import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { BottomBar } from '../components/BottomBar';
import { MainTopBar } from '../components/MainTopBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toast } from '@components/Toast';
import { AxiosResponse } from 'axios';
import Masonry from 'react-masonry-css';
import Lottie from 'lottie-react';
import scissor from '../shared/icons/scissors.json';
import '../shared/style/mainAnimation.css';
import { API, IFeedResponse, IPost } from '../api/API';
import InfiniteScroll from '@shared/utils/InfiniteScroll';
import { useDepthTracking } from '@shared/hooks/useDepthTracking';
import { usePrevTags } from '@shared/hooks/usePrevTags';

import {
    LoginReferer,
    Referer,
    useLoginTracking,
    usePrevTrackingNavigate,
} from '@shared/hooks/usePrevTracking';
import { ampli } from '../ampli';
import { usePrevFeedId } from '@shared/hooks/usePrevFeedId';

interface ImageSkeletonProps {
    showScissors?: boolean;
    className?: string;
    height?: string;
}

const ImageSkeleton: React.FC<ImageSkeletonProps> = ({
    showScissors = true,
    className = 'w-[calc(50vw - 8px)] aspect-[3/4] rounded-lg',
}) => {
    return (
        <div
            className={`aspect-square relative bg-gray-200 animate-pulse rounded-lg ${className} `}
        >
            {showScissors && (
                <div className="absolute inset-0 flex justify-center items-center">
                    <div className="w-12 h-12">
                        <Lottie
                            animationData={scissor}
                            loop={true}
                            autoplay={true}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export const Main = () => {
    const [imgData, setImgData] = useState<IPost[]>([]);
    const [page, setPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(true);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [feedId, setFeedId] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>('');
    const [refresh, setRefresh] = useState<boolean>(false);

    const navigate = useNavigate();
    const location = useLocation();
    const { goForward, clearDepth } = useDepthTracking('main');
    const { clearTags } = usePrevTags('main');
    const { refNavigate, setOrganic } = usePrevTrackingNavigate();
    const { logLoginOrigin } = useLoginTracking();
    const [data, setData] = useState<string>('2024 남자머리');
    const { clearFeedIds } = usePrevFeedId('main');

    useEffect(() => {
        console.log('Main page mounted');
        clearDepth();
        clearTags();
        setOrganic();
        logLoginOrigin(LoginReferer.ICON);
        clearFeedIds();
    }, []);

    const fetchImages = async (pageNumber: number) => {
        setLoading(true);
        setError(null);

        try {
            let res: AxiosResponse<IFeedResponse>;

            switch (data) {
                case '2024 남자머리':
                    if (pageNumber > 1) {
                        if (API.isAuthorized()) {
                            if (refresh) {
                                res = await API.feed.home.refresh();
                            } else {
                                res = await API.feed.home.next(
                                    feedId,
                                    pageNumber,
                                );
                            }
                        } else {
                            if (refresh) {
                                res = await API.feed.homeNoAuth.refresh(feedId);
                            } else {
                                res = await API.feed.homeNoAuth.next(
                                    feedId,
                                    pageNumber,
                                );
                            }
                        }
                    } else {
                        if (API.isAuthorized()) {
                            if (refresh) {
                                res = await API.feed.home.refresh();
                            } else {
                                res = await API.feed.home.get();
                            }
                        } else {
                            if (refresh) {
                                res = await API.feed.homeNoAuth.refresh(feedId);
                            } else {
                                res = await API.feed.homeNoAuth.get();
                            }
                        }
                    }
                    break;
                case '샤기컷 붐은 온다':
                    if (pageNumber > 1) {
                        res = await API.recommend.샤기컷_붐은_온다.next(
                            feedId,
                            pageNumber,
                        );
                    } else if (refresh) {
                        res =
                            await API.recommend.샤기컷_붐은_온다.refresh(
                                feedId,
                            );
                    } else {
                        res = await API.recommend.샤기컷_붐은_온다.get();
                    }
                    break;
                case '장발이 하고싶나?':
                    if (pageNumber > 1) {
                        res = await API.recommend.장발이_하고싶나.next(
                            feedId,
                            pageNumber,
                        );
                    } else if (refresh) {
                        res =
                            await API.recommend.장발이_하고싶나.refresh(feedId);
                    } else {
                        res = await API.recommend.장발이_하고싶나.get();
                    }
                    break;
                case '헤어도 빈티지':
                    if (pageNumber > 1) {
                        res = await API.recommend.헤어도_빈티지.next(
                            feedId,
                            pageNumber,
                        );
                    } else if (refresh) {
                        res = await API.recommend.헤어도_빈티지.refresh(feedId);
                    } else {
                        res = await API.recommend.헤어도_빈티지.get();
                    }
                    break;
                case '집돌이 무드':
                    if (pageNumber > 1) {
                        res = await API.recommend.집돌이_무드.next(
                            feedId,
                            pageNumber,
                        );
                    } else if (refresh) {
                        res = await API.recommend.집돌이_무드.refresh(feedId);
                    } else {
                        res = await API.recommend.집돌이_무드.get();
                    }
                    break;
                case '짧아서 더 멋짐':
                    if (pageNumber > 1) {
                        res = await API.recommend.짧아서_더_멋짐.next(
                            feedId,
                            pageNumber,
                        );
                    } else if (refresh) {
                        res =
                            await API.recommend.짧아서_더_멋짐.refresh(feedId);
                    } else {
                        res = await API.recommend.짧아서_더_멋짐.get();
                    }
                    break;
                case '미친 개성':
                    if (pageNumber > 1) {
                        res = await API.recommend.미친_개성.next(
                            feedId,
                            pageNumber,
                        );
                    } else if (refresh) {
                        res = await API.recommend.미친_개성.refresh(feedId);
                    } else {
                        res = await API.recommend.미친_개성.get();
                    }
                    break;
                case '깔끔하게 해주세요':
                    if (pageNumber > 1) {
                        res = await API.recommend.깔끔하게_해주세요.next(
                            feedId,
                            pageNumber,
                        );
                    } else if (refresh) {
                        res =
                            await API.recommend.깔끔하게_해주세요.refresh(
                                feedId,
                            );
                    } else {
                        res = await API.recommend.깔끔하게_해주세요.get();
                    }
                    break;
                case '뽀글뽀글':
                    if (pageNumber > 1) {
                        res = await API.recommend.뽀글뽀글.next(
                            feedId,
                            pageNumber,
                        );
                    } else if (refresh) {
                        res = await API.recommend.뽀글뽀글.refresh(feedId);
                    } else {
                        res = await API.recommend.뽀글뽀글.get();
                    }
                    break;
            }
            if (res.data.statusCode === 200 && res.data.message === 'success') {
                const {
                    feedId: newFeedId,
                    posts,
                } = res.data.result;

                setImgData((prevData) => {
                    const uniquePosts = Array.from(
                        new Set(
                            [...prevData, ...posts].map((post) => post.postId),
                        ),
                    ).map((id) =>
                        [...prevData, ...posts].find(
                            (post) => post.postId === id,
                        ),
                    );
                    return uniquePosts as IPost[];
                });

                if (!feedId) {
                    setFeedId(newFeedId);
                }
                setHasMore(posts.length > 0);
            } else {
                throw new Error('API response was not successful');
            }
        } catch (error) {
            console.error('Error fetching images:', error);
            setError(
                error instanceof Error
                    ? error.message
                    : 'An unknown error occurred',
            );
            setHasMore(false);
        } finally {
            setLoading(false);
            setRefresh(false);
            console.log(refresh);
        }
    };

    const loadMore = useCallback(() => {
        if (!loading && hasMore) {
            setPage((prevPage) => prevPage + 1);
        }
    }, [loading, hasMore]);

    useEffect(() => {
        fetchImages(page);
    }, [page, data, refresh]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const toast = params.get('toast');
        if (toast) {
            setToastMessage(toast);
            setShowToast(true);
            navigate('/', { replace: true });
        }
    }, [location, navigate]);

    const handleCloseToast = () => {
        setShowToast(false);
    };

    const breakpointColumnsObj = useMemo(
        () => ({
            default: 2,
            1100: 2,
            700: 2,
            500: 2,
        }),
        [],
    );

    const handleImgClick = (postId: string) => {
        const clickedPost = imgData.find((post) => post.postId === postId);
        goForward();
        refNavigate(Referer.HOME)(`/detail/${postId}`, {
            state: {
                clickedPost: clickedPost,
            },
        });
    };

    // const handleImgLoad = (postId: string) => {
        // setLoadedImages((prev) => new Set(prev).add(postId));
    // };

    const handleRecommendChange = (data: string) => {
        setData(data);
        ampli.추천검색어선택({
            keyword: data,
        });
        setPage(1);
        // setLoadedImages(new Set());
        setImgData([]);
        setLoading(true);
    };

    const handleRefresh = () => {
        setPage(1);
        ampli.추천검색어리로드버튼클릭();
        // setLoadedImages(new Set());
        setImgData([]);
        setLoading(true);
        setRefresh(true);
    };

    if (error) {
        return (
            <div className="flex flex-col items-center justify-center h-screen">
                <p className="text-red-500 mb-4">{error}</p>
                <button
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                    onClick={() => fetchImages(1)}
                >
                    Retry
                </button>
            </div>
        );
    }

    return (
        <>
            <MainTopBar
                onChange={handleRecommendChange}
                onRefresh={handleRefresh}
            />
            {imgData && (
                <div className={"w-full px-3"}>
                    <div className="pt-[130px]">
                        <InfiniteScroll
                            loadMore={loadMore}
                            hasMore={hasMore}
                            isLoading={loading}
                        >
                            <Masonry
                                breakpointCols={breakpointColumnsObj}
                                className="my-masonry-grid"
                                columnClassName="my-masonry-grid_column"
                            >
                                {imgData.map((d) => (
                                    <div
                                        key={`${d.postId}`}
                                        className="mb-4 break-inside-avoid content-loaded"
                                    >
                                        <img
                                            src={d.imageUrl}
                                            alt=""
                                            className="w-full object-cover rounded-lg cursor-pointer"
                                            style={{ width: '50vw', minWidth: '160px', minHeight: '250px'}}
                                            onClick={() => handleImgClick(d.postId)}
                                            loading={'lazy'}
                                            // onLoad={() => handleImgLoad(d.postId)}
                                        />
                                        <div className="mt-1 px-1 mb-[26px]">
                                            <p className="font-semibold text-S_Dye_Black truncate overflow-y-hidden">
                                                @{d.uploaderSonigoId.length > 15 ? d.uploaderSonigoId.slice(0, 15) + '..' : d.uploaderSonigoId}
                                            </p>
                                            <p className="text-S_Dye_1 font-medium break-words">
                                                {d.tags.map((tag, index) => (
                                                    <span key={index}>
                                                    #{tag}&nbsp;
                                                </span>
                                                ))}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </Masonry>
                        </InfiniteScroll>
                    </div>
                        {loading ? (
                            <div className="col-span-full w-full">
                                <div className="flex justify-center mb-[80px]">
                                    <div className="w-12 h-12">
                                        <Lottie
                                            animationData={scissor}
                                            loop={true}
                                            autoplay={true}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-wrap justify-center gap-2 ">
                                    {Array.from({ length: 4 }).map((_, index) => (
                                        <ImageSkeleton
                                            key={`skeleton-${index}`}
                                            showScissors={false}
                                            className={
                                                imgData.length === 0
                                                    ? 'w-[calc(46vw-8px)] sm:w-[200px] h-[200px] sm:h-[300px] aspect-[3/4] rounded-lg'
                                                    : ''
                                            }
                                        />
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="py-10"></div>
                        )}
                </div>
            )}
            <div className="fixed max-w-[500px] w-full bottom-0 left-1/2 transform -translate-x-1/2 z-40">
                <BottomBar activePage={'/'} />
            </div>
            <div className="sticky w-full left-0 right-0 bottom-24 z-50">
                {showToast && (
                    <Toast
                        message={toastMessage}
                        duration={3000}
                        onClose={handleCloseToast}
                    />
                )}
            </div>
        </>
    );
};
