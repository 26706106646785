import axios, {
    AxiosError,
    AxiosInstance,
    AxiosResponse,
    InternalAxiosRequestConfig,
} from 'axios';
import Cookies from 'universal-cookie';

export const cookie = new Cookies();

export function getAccessTokenCookie() {
    return cookie.get('access_token');
}

export function setAccessTokenCookie(token: string) {
    if (!token) {
        return;
    }

    cookie.set('access_token', token, { path: '/' });
}

export function clearAccessTokenCookie() {
    cookie.remove('access_token');
}

export interface AccessTokenDTO {
    accessToken: string;
    userId: string;
    sonigoId: string;
}

export interface PhoneNumberTokenDTO {
    phoneNumberToken: string;
    userId: string;
    sonigoId: string;
}

export interface IPost {
    postId: string;
    imageUrl: string;
    uploaderSonigoId: string;
    tags: string[];
}

export interface IFeedResponse {
    statusCode: number;
    message: string;
    result: {
        feedId: string;
        page: number;
        posts: IPost[];
    };
}

export interface IPostDetailResponse {
    statusCode: number;
    message: string;
    result: IPostDetail;
}

export interface IPostDetail {
    id: string;
    uploaderId: string;
    uploaderSonigoId: string;
    contentImageUrl: string;
    tags: string[];
    createdAt: string;
    savedCount: number;
    externalLink: string;
    hasBeenSaved?: boolean;
}

export class API {
    private axios: AxiosInstance;
    private authAxios: AxiosInstance;

    private isAuthorized = false;
    private static instance: API;
    private BASE_URL = 'https://api.sonigo.hair';

    constructor() {
        this.axios = axios.create({
            baseURL: this.BASE_URL,
        });

        this.axios.interceptors.request.use(this.onRequest);
        this.axios.interceptors.response.use(this.onResponse, this.onError);

        API.instance = this;
    }

    static isAuthorized() {
        return API.getInstance().getIsAuthorized();
    }

    static init() {
        if (API.instance) {
            throw new Error('API is already initialized.');
        }

        API.instance = new API();
    }

    static getInstance() {
        if (!API.instance) {
            API.init();
        }

        return API.instance;
    }

    // 엑세스 토큰 쿠키에 있을 때만 부르셈
    private setIsAuthorized(isAuthorized: boolean) {
        this.isAuthorized = isAuthorized;

        if (isAuthorized) {
            this.authAxios = axios.create({
                baseURL: this.BASE_URL,
                headers: {
                    Authorization: `Bearer ${getAccessTokenCookie()}`,
                },
            });

            this.authAxios.interceptors.request.use(this.onRequest);
            this.authAxios.interceptors.response.use(
                this.onResponse,
                this.onError,
            );
            return;
        }

        clearAccessTokenCookie();

        this.authAxios = axios.create({
            baseURL: this.BASE_URL,
        });

        this.authAxios.interceptors.request.use(this.onRequest);
        this.authAxios.interceptors.response.use(this.onResponse, this.onError);
    }

    getIsAuthorized() {
        return this.isAuthorized;
    }

    private onRequest = (config: InternalAxiosRequestConfig) => {
        const { method, url } = config;
        console.log(`[API Request] ${method?.toUpperCase()} ${url}`);

        const accessToken = getAccessTokenCookie();
        if (accessToken) {
            const instance = API.getInstance();
            instance.setIsAuthorized(true);
        }

        return config;
    };

    private onResponse = (res: AxiosResponse) => {
        const { method, url } = res.config;
        const { statusCode, message } = res.data;

        if (!message) {
            console.log(
                `[API Response] ${method?.toUpperCase()} ${url} RESPONSE -> ${res.status} ${message}`,
            );
        }

        if (statusCode === 401) {
            const instance = API.getInstance();
            instance.setIsAuthorized(false);
        }

        if (statusCode !== 200) {
            console.log(
                `[API Response] ${method?.toUpperCase()} ${url} RESPONSE FAILED -> ${res.status}`,
            );
        }

        return res;
    };

    private onError = (error: AxiosError | Error) => {
        if (axios.isAxiosError(error)) {
            const { method, url } = error.config as InternalAxiosRequestConfig;

            console.log(
                `[API Error] ${method?.toUpperCase()} ${url} FAILED -> ${error.response?.status}`,
            );
        } else {
            console.log(`[API Error] ${error.message}`);
        }

        return Promise.reject(error);
    };

    private checkAuthorized(): void | never {
        const instance = API.getInstance();

        if (instance.getIsAuthorized()) {
            return;
        }

        const accessToken = getAccessTokenCookie();
        if (accessToken) {
            instance.setIsAuthorized(true);
            return;
        }

        clearAccessTokenCookie();
        throw new Error('Unauthorized');
    }

    static auth = {
        async SMSRequest(phoneNumber: string): Promise<boolean> {
            const instance = API.getInstance();

            const res = await instance.axios.post('/auth/sms/request-code', {
                phoneNumber,
            });
            return res.data?.statusCode === 200;
        },

        async SMSVerify(
            phoneNumber: string,
            code: string,
        ): Promise<AxiosResponse<AccessTokenDTO | PhoneNumberTokenDTO>> {
            const instance = API.getInstance();

            const res = await instance.axios.post('/auth/sms/verify-code', {
                phoneNumber,
                verificationCode: code,
            });
            if (res.data?.accessToken) {
                setAccessTokenCookie(res.data?.accessToken);
                instance.setIsAuthorized(true);
            }

            return res;
        },

        async SMSSignup(
            sonigoId: string,
            phoneNumberToken: string,
        ): Promise<AxiosResponse<AccessTokenDTO>> {
            const instance = API.getInstance();

            const res = await instance.axios.post('/auth/sms/signup', {
                sonigoId,
                phoneNumberToken,
            });
            if (res.data?.accessToken) {
                setAccessTokenCookie(res.data?.accessToken);
                instance.setIsAuthorized(true);
            }

            return res;
        },
    };

    static async report(url: string) {
        const instance = API.getInstance();

        instance.checkAuthorized();

        return await instance.authAxios.post('/url-report', { url });
    }

    static post = {
        async detail(
            postId: string,
        ): Promise<AxiosResponse<IPostDetailResponse>> {
            const instance = API.getInstance();

            instance.checkAuthorized();

            return await instance.authAxios.get(`/post/details/${postId}`);
        },
    };

    static postNoAuth = {
        async detail(
            postId: string,
        ): Promise<AxiosResponse<IPostDetailResponse>> {
            const instance = API.getInstance();
            return await instance.axios.get(`/post/details/${postId}/unknown`);
        },
    };

    static bookmark = {
        async save(postId: string) {
            const instance = API.getInstance();

            instance.checkAuthorized();

            return await instance.authAxios.post('/save/add', { postId });
        },

        async get() {
            const instance = API.getInstance();

            instance.checkAuthorized();

            return await instance.authAxios.get('/save');
        },

        async unsave(postId: string) {
            const instance = API.getInstance();

            instance.checkAuthorized();

            return await instance.authAxios.post('/save/remove', { postId });
        },
    };

    static recommend = {
        샤기컷_붐은_온다: {
            async get(): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post(
                    '/recommended/샤기컷_붐은_온다',
                );
            },

            async next(
                feedId: string,
                page: number,
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post(
                    '/recommended/샤기컷_붐은_온다/next',
                    { feedId, page },
                );
            },

            async refresh(
                feedId: string,
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post(
                    '/recommended/샤기컷_붐은_온다/refresh',
                    { feedId },
                );
            },
        },

        장발이_하고싶나: {
            async get(): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post(
                    '/recommended/장발이_하고싶니',
                );
            },

            async next(
                feedId: string,
                page: number,
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post(
                    '/recommended/장발이_하고싶니/next',
                    { feedId, page },
                );
            },

            async refresh(
                feedId: string,
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post(
                    '/recommended/장발이_하고싶니/refresh',
                    { feedId },
                );
            },
        },

        헤어도_빈티지: {
            async get(): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post('/recommended/헤어도_빈티지');
            },

            async next(
                feedId: string,
                page: number,
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post(
                    '/recommended/헤어도_빈티지/next',
                    { feedId, page },
                );
            },

            async refresh(
                feedId: string,
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post(
                    '/recommended/헤어도_빈티지/refresh',
                    { feedId },
                );
            },
        },

        집돌이_무드: {
            async get(): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post('/recommended/집돌이_무드');
            },

            async next(
                feedId: string,
                page: number,
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post(
                    '/recommended/집돌이_무드/next',
                    { feedId, page },
                );
            },

            async refresh(
                feedId: string,
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post(
                    '/recommended/집돌이_무드/refresh',
                    { feedId },
                );
            },
        },

        짧아서_더_멋짐: {
            async get(): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post('/recommended/짧아서_더_멋짐');
            },

            async next(
                feedId: string,
                page: number,
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post(
                    '/recommended/짧아서_더_멋짐/next',
                    { feedId, page },
                );
            },

            async refresh(
                feedId: string,
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post(
                    '/recommended/짧아서_더_멋짐/refresh',
                    { feedId },
                );
            },
        },

        미친_개성: {
            async get(): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post('/recommended/미친_개성');
            },

            async next(
                feedId: string,
                page: number,
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post(
                    '/recommended/미친_개성/next',
                    { feedId, page },
                );
            },

            async refresh(
                feedId: string,
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post(
                    '/recommended/미친_개성/refresh',
                    { feedId },
                );
            },
        },

        깔끔하게_해주세요: {
            async get(): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post(
                    '/recommended/깔끔하게_해주세요',
                );
            },

            async next(
                feedId: string,
                page: number,
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post(
                    '/recommended/깔끔하게_해주세요/next',
                    { feedId, page },
                );
            },

            async refresh(
                feedId: string,
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post(
                    '/recommended/깔끔하게_해주세요/refresh',
                    { feedId },
                );
            },
        },

        뽀글뽀글: {
            async get(): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post('/recommended/뽀글뽀글');
            },

            async next(
                feedId: string,
                page: number,
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post('/recommended/뽀글뽀글/next', {
                    feedId,
                    page,
                });
            },

            async refresh(
                feedId: string,
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post(
                    '/recommended/뽀글뽀글/refresh',
                    { feedId },
                );
            },
        },
    };

    static feed = {
        relatedNoAuth: {
            async refresh(
                postId: string,
                feedId: string,
                tags: string[],
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post(
                    `/related/unknown/${postId}/refresh`,
                    { feedId, tags },
                );
            },

            async next(
                postId: string,
                page: number,
                feedId: string,
                tags: string[],
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post(
                    `/related/unknown/${postId}/next`,
                    { page, feedId, tags },
                );
            },

            async get(
                postId: string,
                tags: string[],
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post(`/related/unknown/${postId}`, {
                    tags,
                });
            },
        },

        related: {
            async refresh(
                postId: string,
                feedId: string,
                tags: string[],
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();

                instance.checkAuthorized();

                return await instance.authAxios.post(
                    `/related/${postId}/refresh`,
                    { feedId, tags },
                );
            },

            async next(
                postId: string,
                page: number,
                feedId: string,
                tags: string[],
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();

                instance.checkAuthorized();

                return await instance.authAxios.post(
                    `/related/${postId}/next`,
                    { page, feedId, tags },
                );
            },

            async get(
                postId: string,
                tags: string[],
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();

                instance.checkAuthorized();

                return await instance.authAxios.post(`/related/${postId}`, {
                    tags,
                });
            },
        },

        searchNoAuth: {
            async search(
                tags: string[],
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post(`/search/unknown`, { tags });
            },

            async next(
                feedId: string,
                page: number,
                tags: string[],
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post(`/search/unknown/next`, {
                    feedId,
                    page,
                    tags,
                });
            },

            async refresh(
                feedId: string,
                tags: string[],
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post(`/search/unknown/refresh`, {
                    feedId,
                    tags,
                });
            },
        },

        search: {
            async search(
                tags: string[],
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();

                instance.checkAuthorized();

                return await instance.authAxios.post(`/search`, { tags });
            },

            async next(
                feedId: string,
                page: number,
                tags: string[],
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();

                instance.checkAuthorized();

                return await instance.authAxios.post(`/search/next`, {
                    feedId,
                    page,
                    tags,
                });
            },

            async refresh(
                feedId: string,
                tags: string[],
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();

                instance.checkAuthorized();

                return await instance.authAxios.post(`/search/refresh`, {
                    feedId,
                    tags,
                });
            },
        },

        homeNoAuth: {
            async get(): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post('/feed/unknown');
            },

            async next(
                feedId: string,
                page: number,
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post('/feed/unknown/next', {
                    page,
                    feedId,
                });
            },

            async refresh(
                feedId: string,
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();
                return await instance.axios.post('/feed/unknown/refresh', {
                    feedId,
                });
            },
        },

        home: {
            async get(): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();

                instance.checkAuthorized();

                return await instance.authAxios.post('/feed/me');
            },

            async refresh(): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();

                instance.checkAuthorized();

                return await instance.authAxios.post('/feed/me/refresh');
            },

            async next(
                feedId: string,
                page: number,
            ): Promise<AxiosResponse<IFeedResponse>> {
                const instance = API.getInstance();

                instance.checkAuthorized();

                return await instance.authAxios.post('/feed/me/next', {
                    page,
                    feedId,
                });
            },
        },

        async kill(feedId: string) {
            const instance = API.getInstance();

            instance.checkAuthorized();

            return await instance.authAxios.post(`/feed/kill`, { feedId });
        },

        async killNoAuth(feedId: string) {
            const instance = API.getInstance();
            return await instance.axios.post(`/feed/unknown/kill`, { feedId });
        },
    };
}
