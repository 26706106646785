import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BottomBar } from '../components/BottomBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchDyeDetailTopBar } from '@components/SearchDyeDetailTopBar';
import Lottie from 'lottie-react';
import scissor from "../shared/icons/scissors.json"
import { AxiosResponse } from 'axios';
import { Toast } from '@components/Toast';
import InfiniteScroll from '@shared/utils/InfiniteScroll';
import Masonry from 'react-masonry-css';
import { API, IFeedResponse, IPost } from '../api/API';
import { Referer, usePrevTrackingNavigate } from '@shared/hooks/usePrevTracking';
import { useDepthTracking } from '@shared/hooks/useDepthTracking';
import { usePrev } from '@shared/hooks/usePrev';

interface ImageSkeletonProps {
    showScissors?: boolean;
    className?: string;
    height?: string;
}

const ImageSkeleton: React.FC<ImageSkeletonProps> = ({ showScissors = true, className = "", }) => {
    return (
        <div className={`aspect-square relative bg-gray-200 animate-pulse rounded-lg ${className} `}>
            {showScissors && (
                <div className="absolute inset-0 flex justify-center items-center">
                    <div className="w-12 h-12">
                        <Lottie
                            animationData={scissor}
                            loop={true}
                            autoplay={true}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export const SearchDyeDetail = () => {
    const [imgData, setImgData] = useState<IPost[]>([]);
    const [page, setPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(true);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [feedId, setFeedId] = useState<string | null>(null);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>('');
    const [postInfo, setPostInfo] = useState<IPost | null>(null);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);

    const navigate = useNavigate();
    const location = useLocation();
    const { clickedPost } = location.state || {};
    const { refNavigate, setOrganic } = usePrevTrackingNavigate();


    const [selectedFilters, setSelectedFilters] = useState<Record<string, string[]>>({
        기장: [],
        무드: []
    });
    const [isForwardNavigated, setIsForwardNavigated] = useState<boolean>(false);
    const [isNavigated, setIsNavigated] = useState<boolean>(false);
    const { goForward, clearDepth, currentDepth, prevDepth } = useDepthTracking('main')
    const { appendPrev, popPrev } = usePrev<Record<string, string[]>>('search')

    const toggleNavigated = () => {
        setIsNavigated(!isNavigated);
    }

    useEffect(() => {
        const d = currentDepth();
        const p = prevDepth();
        console.log('DEPTH', d);
        console.log('PREV', p);

        if (prevDepth && d > p) {
            setIsForwardNavigated(true);
            toggleNavigated()
        } else {
            setIsForwardNavigated(false);
            toggleNavigated()
        }
    }, [window.location.pathname]);

    useEffect(() => {
        if (!isForwardNavigated) {
            console.log('팝!!!!!!!!!!!!!!!!!!!!');
            const prevFilter = popPrev();
            setSelectedFilters(prevFilter);
        }

        clearDepth();
    }, [isNavigated, isForwardNavigated]);

    useEffect(() => {
        const { 기장, 무드 } = selectedFilters;
        if (!기장 && !무드) return;

        setSelectedTags([...기장, ...무드]);
    }, [selectedFilters]);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        setOrganic();
    }, []);

    useEffect(() => {
        if (clickedPost) {
            if (postInfo?.postId !== clickedPost.postId) {
                console.log("replace")
            }
            console.log("fetch")

            fetchInitialSearchImages();
            setPostInfo(clickedPost);
        }
    }, [clickedPost]);

    useEffect(() => {
        if (page > 1) {
            fetchNextSearchImages();
        }
    }, [page]);

    useEffect(() => {
        setPage(1);
        setImgData([]);
        setFeedId(null);
        fetchInitialSearchImages();
    }, [selectedTags]);

    const fetchInitialSearchImages = async () => {
        setLoading(true);
        try {
            let res: AxiosResponse<IFeedResponse>;

            if (API.isAuthorized()) {
                res = await API.feed.search.search(selectedTags);
            } else {
                res = await API.feed.searchNoAuth.search(selectedTags);
            }

            if (res.data.statusCode === 200 && res.data.message === 'success') {
                const { posts, feedId: newFeedId } = res.data.result;
                setImgData(posts);
                setFeedId(newFeedId);
                setPage(1);
                setHasMore(posts.length > 0);
            } else {
                throw new Error('API response was not successful');
            }
        } catch (error) {
            console.error('Error fetching initial images:', error);
            setHasMore(false);
        } finally {
            setLoading(false);
        }
    };

    const fetchNextSearchImages = async () => {
        if (!feedId) return;

        setLoading(true);
        try {
            let res: AxiosResponse<IFeedResponse>;

            if (API.isAuthorized()) {
                res = await API.feed.search.next(feedId, page, selectedTags);
            } else {
                res = await API.feed.searchNoAuth.next(feedId, page, selectedTags);
            }

            if (res.data.statusCode === 200 && res.data.message === 'success') {
                const { posts } = res.data.result;
                setImgData(prevData => [...prevData, ...posts]);
                setHasMore(posts.length > 0);
            } else {
                throw new Error('API response was not successful');
            }
        } catch (error) {
            console.error('Error fetching next images:', error);
            setHasMore(false);
        } finally {
            setLoading(false);
        }
    };

    const loadMore = useCallback(() => {
        if (!loading && hasMore) {
            setPage(prevPage => prevPage + 1);
        }
    }, [loading, hasMore]);

    useEffect(() => {
        fetchInitialSearchImages();
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const toast = params.get('toast');
        if (toast) {
            setToastMessage(toast);
            setShowToast(true);
            navigate('/', { replace: true });
        }
    }, [location, navigate]);

    const handleCloseToast = () => {
        setShowToast(false);
    }

    const breakpointColumnsObj = useMemo(() => ({
        default: 2,
        1100: 2,
        700: 2,
        500: 2
    }), []);

    const handleImgClick = (postId: string) => {
        const clickedPost = imgData.find(
            (post) => post.postId === postId,
        );
        goForward()
        appendPrev(selectedFilters)
        refNavigate(Referer.SEARCH)(`/detail/${postId}`, {
            state: {
                clickedPost: clickedPost,
            },
        });
        setImgData([]);
    };


    const handleTagSelect = (tags: string[]) => {
        console.log("Selected tags:", tags);
        setSelectedTags(tags);
        scrollToTop();
    };

    const handleFilterChange = (filters: Record<string, string[]>, tags: string[]) => {
        console.log("Filters changed:", filters, "Tags:", tags);
        setSelectedFilters(filters);
        scrollToTop();
    };


    return (
        <>
        <SearchDyeDetailTopBar
            feedId={feedId}
            onSelect={handleTagSelect}
            onFilterChange={handleFilterChange}
        />
    {imgData && (
        <div className={"w-full px-3"}>
            <div className="pt-[180px]">
                <InfiniteScroll
                    loadMore={loadMore}
                    hasMore={hasMore}
                    isLoading={loading}
                >
                    <Masonry
                        breakpointCols={breakpointColumnsObj}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                    >
                        {imgData.map((d) => (
                            <div
                                key={`${d.postId}`}
                                className="mb-4 break-inside-avoid content-loaded"
                            >
                                <img
                                    src={d.imageUrl}
                                    alt=""
                                    className="w-full object-cover rounded-lg cursor-pointer"
                                    style={{ width: '50vw' }}
                                    onClick={() => handleImgClick(d.postId)}
                                    // onLoad={() => handleImgLoad(d.postId)}
                                />
                                <div className="mt-1 px-1 mb-[26px]">
                                    <p className="font-semibold text-S_Dye_Black truncate overflow-y-hidden">
                                        @{d.uploaderSonigoId.length > 15 ? d.uploaderSonigoId.slice(0, 15) + '..' : d.uploaderSonigoId}
                                    </p>
                                    <p className="text-S_Dye_1 font-medium break-words">
                                        {d.tags.map((tag, index) => (
                                            <span key={index}>
                                                        #{tag}&nbsp;
                                                    </span>
                                        ))}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </Masonry>
                </InfiniteScroll>
            </div>
            {loading ? (
                <div className="col-span-full w-full">
                    <div className="flex justify-center mb-[80px]">
                        <div className="w-12 h-12">
                            <Lottie
                                animationData={scissor}
                                loop={true}
                                autoplay={true}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-center gap-2 ">
                        {Array.from({ length: 4 }).map((_, index) => (
                            <ImageSkeleton
                                key={`skeleton-${index}`}
                                showScissors={false}
                                className={
                                    imgData.length === 0
                                        ? 'w-[calc(46vw-8px)] sm:w-[200px] h-[200px] sm:h-[300px] aspect-[3/4] rounded-lg'
                                        : ''
                                }
                            />
                        ))}
                    </div>
                </div>
            ) : (
                <div className="py-10"></div>
            )}
        </div>
    )}
    <div className="fixed max-w-[500px] w-full bottom-0 left-1/2 transform -translate-x-1/2">
        <BottomBar activePage={'/'} />
    </div>
    <div className="sticky w-full left-0 right-0 bottom-24 z-50">
        {showToast && (
            <Toast
                message={toastMessage}
                duration={3000}
                onClose={handleCloseToast}
            />
        )}
    </div>
</>
    );
};