import React from 'react';

interface BottomBtnProps {
    children: React.ReactNode;
    onClick: () => void;
    disabled: boolean;
    className?: string;
}

export const BottomBtn: React.FC<BottomBtnProps> = ({
    children,
    onClick,
    disabled,
    className,
}) => {
    const baseClasses = 'font-semibold w-full py-4 rounded-full';
    const enabledClasses = 'bg-S_Dye_Black text-S_White';
    const disabledClasses = 'bg-[#CCC] text-S_White';

    const buttonClasses = `${baseClasses} ${disabled ? disabledClasses : enabledClasses} ${className}`;
    return (
        <button className={buttonClasses} onClick={onClick} disabled={disabled}>
            {children}
        </button>
    );
};
