import React from 'react';

interface SearchBtnProps {
    isActive?: boolean;
    onClick?: () => void;
}

export const SearchBtn: React.FC<SearchBtnProps> = ({
    isActive = false,
    onClick,
}) => {
    return (
        <button className="flex flex-col items-center" onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                className="mb-1"
            >
                <g clipPath="url(#clip0_334_955)">
                    <path
                        d="M10.2867 1C6.57907 1 3.57343 4.00564 3.57343 7.71329C3.57343 11.4209 6.57907 14.4266 10.2867 14.4266C13.9944 14.4266 17 11.4209 17 7.71329C17 4.00564 13.9944 1 10.2867 1Z"
                        stroke={`${isActive ? '#FF671F' : '#ACA39A'}`}
                        strokeWidth="1.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M1.00013 17L5.36377 12.6364"
                        stroke={`${isActive ? '#FF671F' : '#ACA39A'}`}
                        strokeWidth="1.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_334_955">
                        <rect
                            width="18"
                            height="18"
                            fill="white"
                            transform="translate(18 18) rotate(-180)"
                        />
                    </clipPath>
                </defs>
            </svg>
            <p
                className={`${isActive ? 'text-S_Wave_Orange' : 'text-S_Dye_1'}`}
            >
                탐색
            </p>
        </button>
    );
};
