import React from 'react'

export const CloseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
            <path d="M9 1.5L1 9.5" stroke="#FF671F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1 1.5L9 9.5" stroke="#FF671F" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
