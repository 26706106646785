import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import 'tailwindcss/tailwind.css';
import { Main } from '@pages/Main';
import { Detail } from '@pages/Detail';
import { Search } from "@pages/Search";
import { SearchCutDetail } from "@pages/SearchCutDetail";
import { Login } from '@pages/Login';
import { NickName } from '@pages/NickName';
import { Upload } from '@pages/Upload';
import { Bookmark } from '@pages/Bookmark';
import { SearchPermDetail } from '@pages/SearchPermDetail';
import { SearchDyeDetail } from '@pages/SearchDyeDetail';
import { Layout } from '@app/layout/Layout';

const CustomRouter = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        errorElement: <span>404</span>,
        children: [
            {
                path: '',
                element: <Main />,
            },
            {
                path: "detail/:postId",
                element: < Detail />,
            },
            {
                path: "search",
                element: < Search />,
            },
            {
                path: "search-cut-detail",
                element: < SearchCutDetail />,
            },
            {
                path: "search-dye-detail",
                element: < SearchDyeDetail />,
            },
            {
                path: "search-perm-detail",
                element: < SearchPermDetail />,
            },
            {
                path: 'login',
                element: <Login />,
            },
            {
                path: 'nickname',
                element: <NickName />,
            },
            {
                path: 'upload',
                element: <Upload />,
            },
            {
                path: 'bookmark',
                element: <Bookmark />,
            },
        ],
    },
]);

export default CustomRouter;
