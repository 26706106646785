import React, { useEffect, useRef, useState } from 'react';
import { FilterDropdownIcon } from '@shared/icons/FilterDropdownIcon';
import { PrevIcon } from '@shared/icons/PrevIcon';
import { RefreshIcon } from '@shared/icons/RefreshIcon';
import { useNavigate } from 'react-router-dom';
import { BottomSheet } from './BottomSheet';
import { useModal } from '@shared/hooks/useModal.hook';
import { womenModalContainer, womenModalLayout, womenModalTransiton } from '@shared/style/womenModal.styled';
import { LegoSadImg } from '@shared/imges/LegoSadImg';
import { CutStyleBottomSheet } from './CutStyleBottomSheet';
import { CutCategoryBottomSheet } from './CutCategoryBottomSheet';
import { API } from '../api/API';
import { ampli } from '../ampli';

const allHairStyles = ['전체', '슬릭컷', '리프컷', '댄디컷'];

interface IData {
    data: string[];
}

const Data: IData = {
    data: ['남자', '기장', '무드'],
};

interface ResearchDetailTopBarProps {
    feedId: string;
    onSelect: (tags: string[]) => void;
    onFilterChange: (filters: Record<string, string[]>, tags: string[]) => void;
    selectedFiltersState?: Record<string, string[]>;
}

export const SearchCutDetailTopBar: React.FC<ResearchDetailTopBarProps> = ({
    feedId,
    onSelect,
    onFilterChange,
   selectedFiltersState,
}) => {
    const [selectedCutStyle, setSelectedCutStyle] = useState<string>('전체');
    const [visibleCutStyles, setVisibleCutStyles] = useState<string[]>(allHairStyles.slice(0, 4));
    const [selectedFilter, setSelectedFilter] = useState<string>('남자');
    const [selectedGender, setSelectedGender] = useState('남자');
    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
    const [isHairstyleBottomSheetOpen, setIsHairstyleBottomSheetOpen] = useState(false);
    const [isCutStyleBottomSheetOpen, setIsCutStyleBottomSheetOpen] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState<Record<string, string[]>>({
        기장: [],
        무드: []
    });
    const [selectedHairCutTag, setSelectedHairCutTag] = useState<string | null>(null);

    const navigate = useNavigate()

    useEffect(() => {
        if (selectedFiltersState) {
            setSelectedFilters(selectedFiltersState);
        }
    }, [selectedFiltersState]);

    const handleOpenBottomSheet = () => setIsBottomSheetOpen(true);
    const handleCloseBottomSheet = () => setIsBottomSheetOpen(false);

    const handleOpenHairstyleBottomSheet = () => setIsHairstyleBottomSheetOpen(true);
    const handleCloseHairstyleBottomSheet = () => setIsHairstyleBottomSheetOpen(false);

    const handleOpenCutStyleBottomSheet = () => setIsCutStyleBottomSheetOpen(true);
    const handleCloseCutStyleBottomSheet = () => setIsCutStyleBottomSheetOpen(false);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handlePrevClick = () => {
        if (API.isAuthorized()) {
            API.feed.kill(feedId);
        } else {
            API.feed.killNoAuth(feedId);
        }

        navigate(-1);
    };

    const womenModalRef = useRef<HTMLDivElement>(null);
    const {
        isModalOpen: womenModalOpen,
        openModal: womenOpenModal,
        closeModal: womenCloseModal,
    } = useModal<null>(null);

    useEffect(() => {
        if (selectedGender === '여자') {
            womenOpenModal();
            setSelectedGender('남자');
        }
    }, [selectedGender]);

    const updateSelectedTags = (hairStyle?: string) => {
        const tags: string[] = [];

        const currentHairStyle = hairStyle || selectedCutStyle;
        if (currentHairStyle !== '전체') {
            tags.push(currentHairStyle);
        }

        Object.values(selectedFilters).forEach(filterTags => {
            tags.push(...filterTags.filter(tag => tag !== currentHairStyle));
        });
        onSelect(tags);
    };

    const handleSelectHairStyle = (style: string, isFromBottomSheet = false) => {
        setSelectedCutStyle(style);
        if (isFromBottomSheet) {
            setVisibleCutStyles([style]);
        }
        setSelectedHairCutTag(style === '전체' ? null : style);
        updateSelectedTags(style);
        scrollToTop();
    };

    const handleFilterChange = (filters: Record<string, string[]>, tags: string[]) => {
        console.log("Filter changed:", filters, "Tags:", tags);
        setSelectedFilters(filters);

        const selectedCategories = Object.entries(filters)
            .filter(([category, items]) => category !== '남자' && items.length > 0)
            .map(([category, items]) => `${category} ${items.length}`);

        if (selectedCategories.length > 0) {
            setSelectedFilter(['남자', ...selectedCategories].join(', '));
        } else {
            setSelectedFilter('남자');
        }

        const updatedTags = selectedHairCutTag ? [selectedHairCutTag, ...tags.filter(tag => tag !== selectedHairCutTag)] : tags;

        ampli.검색시도({
            searchCategory: "cut",
            searchTags: updatedTags
        });
        onFilterChange(filters, updatedTags);
        scrollToTop();
    };



    const handleRefresh = () => {
        const updatedFilters = {
            ...selectedFilters,
            기장: [],
            무드: []
        };
        setSelectedFilters(updatedFilters);
        setSelectedFilter('남자');

        const updatedTags = selectedHairCutTag ? [selectedHairCutTag] : [];
        onFilterChange(updatedFilters, updatedTags);
        scrollToTop();
    };

    return (
        <div className='fixed top-0 left-0 right-0 bg-white z-10 max-h-screen overflow-y-auto'>
            <div className="sticky top-0 bg-white z-20">
                <div className="flex w-full px-4 py-4 items-center">
                    <button onClick={handlePrevClick} className="absolute left-4">
                        <PrevIcon />
                    </button>
                    <div className="flex-grow text-center font-semibold text-lg">커트</div>
                </div>
                <div className={`flex w-full px-4 ${visibleCutStyles.length === 1 ? 'justify-between px-6' : 'justify-between items-center'}`}>
                    <div className={`flex ${visibleCutStyles.length === 1 ? 'flex-grow' : 'gap-[10px]'}`}>
                        {visibleCutStyles.map((d, index) => (
                            <button
                                key={index}
                                onClick={() => {
                                    if (d != '전체') {
                                        ampli.검색용상단태그선택({
                                            searchCategory: "cut",
                                            tag: d,
                                        });
                                    };

                                    handleSelectHairStyle(d);
                                }}
                                className={`py-2 px-[14px] rounded-full
                                ${selectedCutStyle === d
                                        ? 'bg-S_WO_20 text-sm font-medium text-S_Wave_Orange shadow-[inset_0px_2px_1px_0px_#FFBE9F] border-[0.5px] border-[#FFCFBE]'
                                        : 'bg-S_White text-sm font-medium text-S_Dye_1 shadow-[inset_0px_-2px_1px_0px_rgba(0,0,0,0.10)] border-[0.5px] border-S_Dye_White'
                                    }`}
                            >
                                {d}
                            </button>
                        ))}
                    </div>
                    {visibleCutStyles.length > 1 && (
                        <button
                            className=" font-semibold text-sm text-S_Wave_Orange ml-2"
                            onClick={handleOpenHairstyleBottomSheet}
                        >
                            더보기
                        </button>
                    )}
                </div>
                <div className='w-full h-[2px] bg-S_Dye_White mt-[10px]'></div>
            </div>
            <div className="flex w-full overflow-hidden mb-2 pl-4 mt-[10px]">
                <button className="flex-shrink-0 mr-2" onClick={handleRefresh}>
                    <RefreshIcon />
                </button>
                <div className="flex-1 overflow-hidden">
                    <ul className="flex items-center flex-nowrap whitespace-nowrap gap-2 overflow-x-auto scrollbar-hide">
                        {Data.data.map((d, index) => (
                            <li
                                key={index}
                                onClick={() => {
                                    if (d === '남자') {
                                        ampli.검색용필터열기({
                                            filterKind: "gender",
                                            searchCatagory: "cut"
                                        });
                                        handleOpenBottomSheet();
                                    } else if (d == "기장") {
                                        ampli.검색용필터열기({
                                            filterKind: "mood",
                                            searchCatagory: "cut"
                                        });
                                        handleOpenCutStyleBottomSheet();
                                    } else if (d == "무드") {
                                        ampli.검색용필터열기({
                                            filterKind: "mood",
                                            searchCatagory: "cut"
                                        });
                                        handleOpenCutStyleBottomSheet();
                                    }
                                }}
                                className={`flex items-center gap-2 flex-shrink-0 py-2 px-3 font-medium rounded-[4px] cursor-pointer
                                ${selectedFilter.includes(d)
                                        ? 'border-[1.2px] border-S_Wave_Orange text-S_Wave_Orange'
                                        : 'border-[0.5px] border-S_Dye_1 text-S_Dye_1'
                                    }`}
                            >
                                <span>{d === '남자' ? d : selectedFilter.split(', ').find(f => f.startsWith(d)) || `${d} 전체`}</span>
                                <FilterDropdownIcon
                                    color={selectedFilter.includes(d) ? '#FFA26B' : '#6E6E6E'}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <BottomSheet
                isOpen={isBottomSheetOpen}
                onClose={handleCloseBottomSheet}
                setSelectedGender={setSelectedGender}
            />
            <CutCategoryBottomSheet
                isOpen={isHairstyleBottomSheetOpen}
                onClose={handleCloseHairstyleBottomSheet}
                setSelectedHairStyle={(style) => handleSelectHairStyle(style, true)}
            />
            <CutStyleBottomSheet
                isOpen={isCutStyleBottomSheetOpen}
                onClose={handleCloseCutStyleBottomSheet}
                onFilterChange={handleFilterChange}
                initialFilters={selectedFilters}
                selectedHairCutTag={selectedHairCutTag}
            />

            {womenModalOpen && (
                <div
                    className={`${womenModalOpen ? 'opacity-100 ' : 'opacity-0'} ${womenModalTransiton.class}`}
                >
                    <div className={womenModalLayout.class}>
                        <div
                            ref={womenModalRef}
                            className={womenModalContainer.class}
                        >
                            <LegoSadImg />
                            <p className="mt-8 font-medium text-[#000000]">
                                여자 머리는 준비 중입니다...
                            </p>
                            <p className="mb-[35px] font-medium text-[#000000]">
                                죄송합니다...
                            </p>
                            <button
                                onClick={() => womenCloseModal()}
                                className="w-[328px] h-[41px] text-center rounded-[4px] bg-S_Dye_Black"
                            >
                                <p className="place-content-center text-lg font-semibold text-S_White">
                                    확인
                                </p>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};