import React, { useEffect, useState } from 'react';
import { XBtn } from '@shared/buttons/XBtn';
import { LegoGoodImg } from '@shared/imges/LegoGoodImg';
import { BottomBtn } from '@components/BottomBtn';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import axios from 'axios';
import { useAccessToken } from '@features/useAccessToken.hook';
import { ampli } from '../ampli';
import { Toast } from '@components/Toast';

export const Upload = () => {
    const navigate = useNavigate();
    const { accessToken } = useAccessToken();

    const [instaURL, setInstaURL] = useState<string>('');
    const [isValid, setIsValid] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);

    useEffect(() => {
        if (instaURL.length > 0) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [instaURL]);

    const handleSend = async () => {
        try {
            const res = await axios.post(
                'https://test.jam.ong/url-report',
                {
                    url: instaURL,
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + accessToken,
                    },
                },
            );
            console.log(res);
            ampli.url업로드({
                url: instaURL
            })
            navigate('/?toast=전송이 완료됐어요!');
        } catch (error) {
            console.error('전송 에러', error);
            setShowToast(true);
        } finally {
            setInstaURL('');
        }
    };

    return (
        <AnimatePresence>
            <motion.div
                initial={{ y: '100%' }}
                animate={{ y: 0 }}
                transition={{ duration: 0.5, ease: 'easeOut' }}
                className="flex flex-col w-full px-4 h-dvh bg-white"
            >
                <div className="flex flex-col w-full h-dvh">
                    {/* 상단 바 */}
                    <div className="flex justify-end py-[14px]">
                        <button onClick={() => navigate('/')}>
                            <XBtn />
                        </button>
                    </div>
                    <div className="flex-grow flex flex-col">
                        <div className="text-center mt-[17.55px]">
                            <p className="text-S_Dye_5 text-sm font-medium">
                                업로드는 저희가 할게요!
                            </p>
                            <p className="text-S_Dye_Black text-xl font-semibold">
                                인스타그램 게시글 링크를 알려주세요.
                            </p>
                        </div>

                        <div className="mt-8">
                            <label
                                htmlFor="instagramLink"
                                className="flex justify-center"
                            >
                                <input
                                    type="text"
                                    name="instagramLink"
                                    id="instagramLink"
                                    placeholder="URL을 입력해 주세요."
                                    onChange={(e) =>
                                        setInstaURL(e.target.value)
                                    }
                                    className="bg-S_Dye_White w-full py-[15px] px-[19px] rounded-[4px]"
                                />
                            </label>
                        </div>

                        <div className="relative bg-S_WO_20 mt-[128.23px] text-center rounded-lg">
                            <div className="text-S_Wave_Orange py-[17px] px-[24px] text-sm font-medium">
                                <p>보내주신 링크는 손이고팀에서</p>
                                <p>검수 후 업로드됩니다!!</p>
                                <p>모든 스타일을 소중하게 여기겠습니다.</p>
                            </div>
                            <div className="flex justify-center items-center">
                                <svg
                                    width="32"
                                    height="31.91"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="absolute"
                                >
                                    <path
                                        d="M0 0L32 0L16 32L0 0Z"
                                        fill="#FFE1D2"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div className="flex justify-center items-center mt-[27.93px]">
                            <LegoGoodImg />
                        </div>
                    </div>
                    <div className="sticky w-full left-0 right-0 bottom-24">
                        {showToast && (
                            <Toast
                                message={'전체 링크를 입력해주세요.'}
                                duration={30000}
                                onClose={() => setShowToast(false)}
                            />
                        )}
                    </div>
                    <div className="mb-[30px]">
                        <BottomBtn onClick={handleSend} disabled={!isValid}>
                            전송하기
                        </BottomBtn>
                    </div>
                </div>
            </motion.div>
        </AnimatePresence>
    );
};
