/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull sonigo-core-web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 1
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/point3-218383/sonigo-core-dev/implementation/sonigo-core-web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'sonigocoredev';

export const ApiKey: Record<Environment, string> = {
    sonigocoredev: 'cf72bbfb979258f45efb7558982d3d9f',
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
    plan: {
        version: '1',
        branch: 'main',
        source: 'sonigo-core-web',
        versionId: '6526e4c0-cabc-44bc-8f2d-95d0d8c6c9b9',
    },
    ...{
        ingestionMetadata: {
            sourceName: 'browser-typescript-ampli',
            sourceVersion: '2.0.0',
        },
    },
};

export interface LoadOptionsBase {
    disabled?: boolean;
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
    environment: Environment;
    client?: { configuration?: BrowserOptions };
};
export type LoadOptionsWithApiKey = LoadOptionsBase & {
    client: { apiKey: string; configuration?: BrowserOptions };
};
export type LoadOptionsWithClientInstance = LoadOptionsBase & {
    client: { instance: BrowserClient };
};

export type LoadOptions =
    | LoadOptionsWithEnvironment
    | LoadOptionsWithApiKey
    | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
    /**
     * sonigo ID
     */
    'sonigo ID'?: string;
}

export interface UrlProperties {
    /**
     * URL 입니다
     */
    url: string;
}

export interface Properties {
    /**
     * 검색을 위한 대분류 입니다
     *
     * | Rule | Value |
     * |---|---|
     * | Enum Values | cut, perm, color |
     */
    searchCategory: 'cut' | 'perm' | 'color';
}

export interface Properties {
    /**
     * 검색을 위한 대분류 입니다
     *
     * | Rule | Value |
     * |---|---|
     * | Enum Values | cut, perm, color |
     */
    searchCategory: 'cut' | 'perm' | 'color';
    /**
     * 유저가 검색할때 포함한 태그들입니다
     *
     * | Rule | Value |
     * |---|---|
     * | Item Type | string |
     */
    searchTags: string[];
}

export interface Properties {
    /**
     * 검색을 위한 대분류 입니다
     *
     * | Rule | Value |
     * |---|---|
     * | Enum Values | cut, perm, color |
     */
    searchCategory: 'cut' | 'perm' | 'color';
}

export interface Properties {
    /**
     * 검색을 위한 대분류 입니다
     *
     * | Rule | Value |
     * |---|---|
     * | Enum Values | cut, perm, color |
     */
    searchCategory: 'cut' | 'perm' | 'color';
    /**
     * 단일 태그 입니다
     */
    tag: string;
}

export interface Properties {
    /**
     * 검색을 위한 대분류 입니다
     *
     * | Rule | Value |
     * |---|---|
     * | Enum Values | cut, perm, color |
     */
    searchCategory: 'cut' | 'perm' | 'color';
    /**
     * 단일 태그 입니다
     */
    tag: string;
}

export interface Properties {
    /**
     * 검색 필터로 들어온 버튼 경로 입니다
     *
     * | Rule | Value |
     * |---|---|
     * | Enum Values | gender, length, mood, weight |
     */
    filterKind: 'gender' | 'length' | 'mood' | 'weight';
    /**
     * 검색을 위한 대분류 입니다
     *
     * | Rule | Value |
     * |---|---|
     * | Enum Values | cut, perm, color |
     */
    searchCategory: 'cut' | 'perm' | 'color';
}

export interface Properties {
    /**
     * 유저가 로그인 페이지를 들어오게된 경로입니다
     *
     * | Rule | Value |
     * |---|---|
     * | Enum Values | organic, saveButton, icon, saveTab |
     */
    loginOrigin: 'organic' | 'saveButton' | 'icon' | 'saveTab';
}

export interface Properties {
    /**
     * 포스트를 본 페이지의 깊이입니다
     *
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     * | Min Value | 0 |
     */
    depth: number;
    /**
     * 유저가 포스트를 어디서 봤는지 알려줍니다
     *
     * | Rule | Value |
     * |---|---|
     * | Enum Values | organic, search, home, save |
     */
    origin: 'organic' | 'search' | 'home' | 'save';
    /**
     * 포스트의 주인입니다
     */
    ownedBy: string;
    /**
     * 포스트 아이디 입니다
     */
    postId: string;
    /**
     * 포스트가 가진 태그들입니다
     *
     * | Rule | Value |
     * |---|---|
     * | Item Type | string |
     */
    tags: string[];
}

export interface Properties {
    /**
     * 추천 키워드입니다
     *
     * | Rule | Value |
     * |---|---|
     * | Enum Values | 샤기컷 붐은 온다, 장발이 하고싶나?, 헤어도 빈티지, 집돌이 무드, 짧아서 더 멋짐, 미친 개성, 깔끔하게 해주세요, 뽀글뽀글 |
     */
    keyword:
        | '샤기컷 붐은 온다'
        | '장발이 하고싶나?'
        | '헤어도 빈티지'
        | '집돌이 무드'
        | '짧아서 더 멋짐'
        | '미친 개성'
        | '깔끔하게 해주세요'
        | '뽀글뽀글';
}

export interface Properties {
    /**
     * 포스트를 본 페이지의 깊이입니다
     *
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     * | Min Value | 0 |
     */
    depth: number;
    /**
     * 유저가 포스트를 어디서 봤는지 알려줍니다
     *
     * | Rule | Value |
     * |---|---|
     * | Enum Values | organic, search, home, save |
     */
    origin: 'organic' | 'search' | 'home' | 'save';
    /**
     * 포스트의 주인입니다
     */
    ownedBy: string;
    /**
     * 포스트 아이디 입니다
     */
    postId: string;
    /**
     * | Rule | Value |
     * |---|---|
     * | Item Type | string |
     */
    tags: string[];
}

export interface Properties {
    /**
     * 포스트를 본 페이지의 깊이입니다
     *
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     * | Min Value | 0 |
     */
    depth: number;
    /**
     * 유저가 포스트를 어디서 봤는지 알려줍니다
     *
     * | Rule | Value |
     * |---|---|
     * | Enum Values | organic, search, home, save |
     */
    origin: 'organic' | 'search' | 'home' | 'save';
    /**
     * 포스트의 주인입니다
     */
    ownedBy: string;
    /**
     * 포스트 아이디 입니다
     */
    postId: string;
    /**
     * 포스트가 가진 태그들입니다
     *
     * | Rule | Value |
     * |---|---|
     * | Item Type | string |
     */
    tags: string[];
}

export interface Properties {
    /**
     * 포스트를 본 페이지의 깊이입니다
     *
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     * | Min Value | 0 |
     */
    depth: number;
    /**
     * 유저가 포스트를 어디서 봤는지 알려줍니다
     *
     * | Rule | Value |
     * |---|---|
     * | Enum Values | organic, search, home, save |
     */
    origin: 'organic' | 'search' | 'home' | 'save';
    /**
     * 포스트의 주인입니다
     */
    ownedBy: string;
    /**
     * 포스트 아이디 입니다
     */
    postId: string;
    /**
     * 포스트가 가진 태그들입니다
     *
     * | Rule | Value |
     * |---|---|
     * | Item Type | string |
     */
    tags: string[];
}

export interface Properties {
    /**
     * 유저가 포스트를 어디서 봤는지 알려줍니다
     *
     * | Rule | Value |
     * |---|---|
     * | Enum Values | organic, search, home, save |
     */
    origin: 'organic' | 'search' | 'home' | 'save';
    /**
     * 포스트의 주인입니다
     */
    ownedBy: string;
    /**
     * 포스트 아이디 입니다
     */
    postId: string;
    /**
     * 포스트가 가진 태그들입니다
     *
     * | Rule | Value |
     * |---|---|
     * | Item Type | string |
     */
    tags: string[];
}

export interface Properties {
    /**
     * 포스트를 본 페이지의 깊이입니다
     *
     * | Rule | Value |
     * |---|---|
     * | Type | integer |
     * | Min Value | 0 |
     */
    depth: number;
    /**
     * 유저가 포스트를 어디서 봤는지 알려줍니다
     *
     * | Rule | Value |
     * |---|---|
     * | Enum Values | organic, search, home, save |
     */
    origin: 'organic' | 'search' | 'home' | 'save';
    /**
     * 포스트의 주인입니다
     */
    ownedBy: string;
    /**
     * 포스트 아이디 입니다
     */
    postId: string;
    /**
     * 포스트가 가진 태그들입니다
     *
     * | Rule | Value |
     * |---|---|
     * | Item Type | string |
     */
    tags: string[];
}

export class Identify implements BaseEvent {
    event_type = amplitude.Types.SpecialEventType.IDENTIFY;

    constructor(public event_properties?: IdentifyProperties) {
        this.event_properties = event_properties;
    }
}

export class Url업로드 implements BaseEvent {
    event_type = 'URL 업로드';

    constructor(public event_properties: Url업로드Properties) {
        this.event_properties = event_properties;
    }
}

export class 검색대분류버튼클릭 implements BaseEvent {
    event_type = '검색 대 분류 버튼 클릭';

    constructor(public event_properties: 검색대분류버튼클릭Properties) {
        this.event_properties = event_properties;
    }
}

export class 검색시도 implements BaseEvent {
    event_type = '검색 시도';

    constructor(public event_properties: 검색시도Properties) {
        this.event_properties = event_properties;
    }
}

export class 검색용상단태그더보기클릭 implements BaseEvent {
    event_type = '검색 용 상단 태그 더보기 클릭';

    constructor(public event_properties: 검색용상단태그더보기클릭Properties) {
        this.event_properties = event_properties;
    }
}

export class 검색용상단태그더보기에서태그선택 implements BaseEvent {
    event_type = '검색 용 상단 태그 더보기에서 태그 선택';

    constructor(
        public event_properties: 검색용상단태그더보기에서태그선택Properties,
    ) {
        this.event_properties = event_properties;
    }
}

export class 검색용상단태그선택 implements BaseEvent {
    event_type = '검색 용 상단 태그 선택';

    constructor(public event_properties: 검색용상단태그선택Properties) {
        this.event_properties = event_properties;
    }
}

export class 검색용필터열기 implements BaseEvent {
    event_type = '검색 용 필터 열기';

    constructor(public event_properties: 검색용필터열기Properties) {
        this.event_properties = event_properties;
    }
}

export class 검색태그초기화버튼클릭 implements BaseEvent {
    event_type = '검색 태그 초기화 버튼 클릭';
}

export class 검색탭 implements BaseEvent {
    event_type = '검색 탭';
}

export class 로그아웃 implements BaseEvent {
    event_type = '로그아웃';
}

export class 로그인완료 implements BaseEvent {
    event_type = '로그인 완료';
}

export class 로그인코드전송 implements BaseEvent {
    event_type = '로그인 코드 전송';
}

export class 로그인화면진입 implements BaseEvent {
    event_type = '로그인 화면 진입';

    constructor(public event_properties: 로그인화면진입Properties) {
        this.event_properties = event_properties;
    }
}

export class 업로드탭 implements BaseEvent {
    event_type = '업로드 탭';
}

export class 인스타그램방문버튼클릭 implements BaseEvent {
    event_type = '인스타그램 방문 버튼 클릭';

    constructor(public event_properties: 인스타그램방문버튼클릭Properties) {
        this.event_properties = event_properties;
    }
}

export class 저장탭 implements BaseEvent {
    event_type = '저장 탭';
}

export class 추천검색어리로드버튼클릭 implements BaseEvent {
    event_type = '추천 검색어 리로드 버튼 클릭';
}

export class 추천검색어선택 implements BaseEvent {
    event_type = '추천 검색어 선택';

    constructor(public event_properties: 추천검색어선택Properties) {
        this.event_properties = event_properties;
    }
}

export class 포스트공유시도 implements BaseEvent {
    event_type = '포스트 공유 시도';

    constructor(public event_properties: 포스트공유시도Properties) {
        this.event_properties = event_properties;
    }
}

export class 포스트자세히보기썸네일클릭 implements BaseEvent {
    event_type = '포스트 자세히 보기 (썸네일 클릭)';

    constructor(public event_properties: 포스트자세히보기썸네일클릭Properties) {
        this.event_properties = event_properties;
    }
}

export class 포스트저장 implements BaseEvent {
    event_type = '포스트 저장';

    constructor(public event_properties: 포스트저장Properties) {
        this.event_properties = event_properties;
    }
}

export class 포스트저장취소 implements BaseEvent {
    event_type = '포스트 저장 취소';

    constructor(public event_properties: 포스트저장취소Properties) {
        this.event_properties = event_properties;
    }
}

export class 포스트조회 implements BaseEvent {
    event_type = '포스트 조회';

    constructor(public event_properties: 포스트조회Properties) {
        this.event_properties = event_properties;
    }
}

export class 홈탭 implements BaseEvent {
    event_type = '홈 탭';
}

export class 회원가입완료 implements BaseEvent {
    event_type = '회원가입 완료';
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * URL 업로드
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/URL%20%EC%97%85%EB%A1%9C%EB%93%9C)
   *
   * 유저가 URL을 제보하였습니다
   *
   * @param properties The event's properties (e.g. url)
   * @param options Amplitude event options.
   */
  url업로드(
    properties: Url업로드Properties,
    options?: EventOptions,
  ) {
    return this.track(new Url업로드(properties), options);
  }

  /**
   * 검색 대 분류 버튼 클릭
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/%EA%B2%80%EC%83%89%20%EB%8C%80%20%EB%B6%84%EB%A5%98%20%EB%B2%84%ED%8A%BC%20%ED%81%B4%EB%A6%AD)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. searchCategory)
   * @param options Amplitude event options.
   */
  검색대분류버튼클릭(
    properties: 검색대분류버튼클릭Properties,
    options?: EventOptions,
  ) {
    return this.track(new 검색대분류버튼클릭(properties), options);
  }

  /**
   * 검색 시도
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/%EA%B2%80%EC%83%89%20%EC%8B%9C%EB%8F%84)
   *
   * 검색 탭에서 유저가 태그 조합으로 피드 검색을 시도하였습니다
   *
   * @param properties The event's properties (e.g. searchCategory)
   * @param options Amplitude event options.
   */
  검색시도(
    properties: 검색시도Properties,
    options?: EventOptions,
  ) {
    return this.track(new 검색시도(properties), options);
  }

  /**
   * 검색 용 상단 태그 더보기 클릭
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/%EA%B2%80%EC%83%89%20%EC%9A%A9%20%EC%83%81%EB%8B%A8%20%ED%83%9C%EA%B7%B8%20%EB%8D%94%EB%B3%B4%EA%B8%B0%20%ED%81%B4%EB%A6%AD)
   *
   * 유저가 태그를 더 확인하기 위해 더보기를 눌렀습니다
   *
   * @param properties The event's properties (e.g. searchCategory)
   * @param options Amplitude event options.
   */
  검색용상단태그더보기클릭(
    properties: 검색용상단태그더보기클릭Properties,
    options?: EventOptions,
  ) {
    return this.track(new 검색용상단태그더보기클릭(properties), options);
  }

  /**
   * 검색 용 상단 태그 더보기에서 태그 선택
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/%EA%B2%80%EC%83%89%20%EC%9A%A9%20%EC%83%81%EB%8B%A8%20%ED%83%9C%EA%B7%B8%20%EB%8D%94%EB%B3%B4%EA%B8%B0%EC%97%90%EC%84%9C%20%ED%83%9C%EA%B7%B8%20%EC%84%A0%ED%83%9D)
   *
   * 유저가 상단 태그 더보기에서 태그를 적어도 1개 선택 하였습니다
   *
   * @param properties The event's properties (e.g. searchCategory)
   * @param options Amplitude event options.
   */
  검색용상단태그더보기에서태그선택(
    properties: 검색용상단태그더보기에서태그선택Properties,
    options?: EventOptions,
  ) {
    return this.track(new 검색용상단태그더보기에서태그선택(properties), options);
  }

  /**
   * 검색 용 상단 태그 선택
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/%EA%B2%80%EC%83%89%20%EC%9A%A9%20%EC%83%81%EB%8B%A8%20%ED%83%9C%EA%B7%B8%20%EC%84%A0%ED%83%9D)
   *
   * 유저가 검색하기 위해 상단 태그를 선택하였습니다.
   *
   * @param properties The event's properties (e.g. searchCategory)
   * @param options Amplitude event options.
   */
  검색용상단태그선택(
    properties: 검색용상단태그선택Properties,
    options?: EventOptions,
  ) {
    return this.track(new 검색용상단태그선택(properties), options);
  }

  /**
   * 검색 용 필터 열기
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/%EA%B2%80%EC%83%89%20%EC%9A%A9%20%ED%95%84%ED%84%B0%20%EC%97%B4%EA%B8%B0)
   *
   * 유저가 검색용 필터를 열었습니다
   *
   * @param properties The event's properties (e.g. filterKind)
   * @param options Amplitude event options.
   */
  검색용필터열기(
    properties: 검색용필터열기Properties,
    options?: EventOptions,
  ) {
    return this.track(new 검색용필터열기(properties), options);
  }

  /**
   * 검색 태그 초기화 버튼 클릭
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/%EA%B2%80%EC%83%89%20%ED%83%9C%EA%B7%B8%20%EC%B4%88%EA%B8%B0%ED%99%94%20%EB%B2%84%ED%8A%BC%20%ED%81%B4%EB%A6%AD)
   *
   * 유저가 검색 탭의 검색 태그들을 초기화 하였습니다
   *
   * @param options Amplitude event options.
   */
  검색태그초기화버튼클릭(
    options?: EventOptions,
  ) {
    return this.track(new 검색태그초기화버튼클릭(), options);
  }

  /**
   * 검색 탭
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/%EA%B2%80%EC%83%89%20%ED%83%AD)
   *
   * 유저가 검색 탭 버튼 선택하였습니다
   *
   * @param options Amplitude event options.
   */
  검색탭(
    options?: EventOptions,
  ) {
    return this.track(new 검색탭(), options);
  }

  /**
   * 로그아웃
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/%EB%A1%9C%EA%B7%B8%EC%95%84%EC%9B%83)
   *
   * 유저가 로그아웃 하였습니다
   *
   * @param options Amplitude event options.
   */
  로그아웃(
    options?: EventOptions,
  ) {
    return this.track(new 로그아웃(), options);
  }

  /**
   * 로그인 완료
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/%EB%A1%9C%EA%B7%B8%EC%9D%B8%20%EC%99%84%EB%A3%8C)
   *
   * 유저가 로그인을 완료했을때 발생하는 이벤트입니다
   *
   * @param options Amplitude event options.
   */
  로그인완료(
    options?: EventOptions,
  ) {
    return this.track(new 로그인완료(), options);
  }

  /**
   * 로그인 코드 전송
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/%EB%A1%9C%EA%B7%B8%EC%9D%B8%20%EC%BD%94%EB%93%9C%20%EC%A0%84%EC%86%A1)
   *
   * 유저가 전화번호를 입력하고 코드를 전송하는 이벤트 입니다
   *
   * @param options Amplitude event options.
   */
  로그인코드전송(
    options?: EventOptions,
  ) {
    return this.track(new 로그인코드전송(), options);
  }

  /**
   * 로그인 화면 진입
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/%EB%A1%9C%EA%B7%B8%EC%9D%B8%20%ED%99%94%EB%A9%B4%20%EC%A7%84%EC%9E%85)
   *
   * 유저가 로그인/가입 을 하기 위해 로그인 페이지로 들어왔습니다
   *
   * @param properties The event's properties (e.g. loginOrigin)
   * @param options Amplitude event options.
   */
  로그인화면진입(
    properties: 로그인화면진입Properties,
    options?: EventOptions,
  ) {
    return this.track(new 로그인화면진입(properties), options);
  }

  /**
   * 업로드 탭
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/%EC%97%85%EB%A1%9C%EB%93%9C%20%ED%83%AD)
   *
   * 유저가 업로드 버튼을 눌렀습니다
   *
   * @param options Amplitude event options.
   */
  업로드탭(
    options?: EventOptions,
  ) {
    return this.track(new 업로드탭(), options);
  }

  /**
   * 인스타그램 방문 버튼 클릭
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/%EC%9D%B8%EC%8A%A4%ED%83%80%EA%B7%B8%EB%9E%A8%20%EB%B0%A9%EB%AC%B8%20%EB%B2%84%ED%8A%BC%20%ED%81%B4%EB%A6%AD)
   *
   * 유저가 인스타그램 버튼을 클릭하였습니다
   *
   * @param properties The event's properties (e.g. depth)
   * @param options Amplitude event options.
   */
  인스타그램방문버튼클릭(
    properties: 인스타그램방문버튼클릭Properties,
    options?: EventOptions,
  ) {
    return this.track(new 인스타그램방문버튼클릭(properties), options);
  }

  /**
   * 저장 탭
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/%EC%A0%80%EC%9E%A5%20%ED%83%AD)
   *
   * 유저가 저장 탭을 눌렀습니다
   *
   * @param options Amplitude event options.
   */
  저장탭(
    options?: EventOptions,
  ) {
    return this.track(new 저장탭(), options);
  }

  /**
   * 추천 검색어 리로드 버튼 클릭
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/%EC%B6%94%EC%B2%9C%20%EA%B2%80%EC%83%89%EC%96%B4%20%EB%A6%AC%EB%A1%9C%EB%93%9C%20%EB%B2%84%ED%8A%BC%20%ED%81%B4%EB%A6%AD)
   *
   * 유저가 추천 검색어 리로드 버튼을 눌렀습니다
   *
   * @param options Amplitude event options.
   */
  추천검색어리로드버튼클릭(
    options?: EventOptions,
  ) {
    return this.track(new 추천검색어리로드버튼클릭(), options);
  }

  /**
   * 추천 검색어 선택
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/%EC%B6%94%EC%B2%9C%20%EA%B2%80%EC%83%89%EC%96%B4%20%EC%84%A0%ED%83%9D)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. keyword)
   * @param options Amplitude event options.
   */
  추천검색어선택(
    properties: 추천검색어선택Properties,
    options?: EventOptions,
  ) {
    return this.track(new 추천검색어선택(properties), options);
  }

  /**
   * 포스트 공유 시도
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/%ED%8F%AC%EC%8A%A4%ED%8A%B8%20%EA%B3%B5%EC%9C%A0%20%EC%8B%9C%EB%8F%84)
   *
   * 유저가 포스트를 공유 시도했을 때 발생하는 이벤트입니다
   *
   * @param properties The event's properties (e.g. depth)
   * @param options Amplitude event options.
   */
  포스트공유시도(
    properties: 포스트공유시도Properties,
    options?: EventOptions,
  ) {
    return this.track(new 포스트공유시도(properties), options);
  }

  /**
   * 포스트 자세히 보기 (썸네일 클릭)
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/%ED%8F%AC%EC%8A%A4%ED%8A%B8%20%EC%9E%90%EC%84%B8%ED%9E%88%20%EB%B3%B4%EA%B8%B0%20(%EC%8D%B8%EB%84%A4%EC%9D%BC%20%ED%81%B4%EB%A6%AD))
   *
   * 유저가 포스트 자세히 보기를 시도 하였습니다.
   *
   * @param properties The event's properties (e.g. depth)
   * @param options Amplitude event options.
   */
  포스트자세히보기썸네일클릭(
    properties: 포스트자세히보기썸네일클릭Properties,
    options?: EventOptions,
  ) {
    return this.track(new 포스트자세히보기썸네일클릭(properties), options);
  }

  /**
   * 포스트 저장
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/%ED%8F%AC%EC%8A%A4%ED%8A%B8%20%EC%A0%80%EC%9E%A5)
   *
   * 유저가 포스트를 저장했을때 발생하는 이벤트입니다
   *
   * @param properties The event's properties (e.g. depth)
   * @param options Amplitude event options.
   */
  포스트저장(
    properties: 포스트저장Properties,
    options?: EventOptions,
  ) {
    return this.track(new 포스트저장(properties), options);
  }

  /**
   * 포스트 저장 취소
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/%ED%8F%AC%EC%8A%A4%ED%8A%B8%20%EC%A0%80%EC%9E%A5%20%EC%B7%A8%EC%86%8C)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. origin)
   * @param options Amplitude event options.
   */
  포스트저장취소(
    properties: 포스트저장취소Properties,
    options?: EventOptions,
  ) {
    return this.track(new 포스트저장취소(properties), options);
  }

  /**
   * 포스트 조회
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/%ED%8F%AC%EC%8A%A4%ED%8A%B8%20%EC%A1%B0%ED%9A%8C)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. depth)
   * @param options Amplitude event options.
   */
  포스트조회(
    properties: 포스트조회Properties,
    options?: EventOptions,
  ) {
    return this.track(new 포스트조회(properties), options);
  }

  /**
   * 홈 탭
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/%ED%99%88%20%ED%83%AD)
   *
   * 유저가 홈 탭을 선택하였습니다
   *
   * @param options Amplitude event options.
   */
  홈탭(
    options?: EventOptions,
  ) {
    return this.track(new 홈탭(), options);
  }

  /**
   * 회원가입 완료
   *
   * [View in Tracking Plan](https://data.amplitude.com/point3-218383/sonigo-core-dev/events/main/latest/%ED%9A%8C%EC%9B%90%EA%B0%80%EC%9E%85%20%EC%99%84%EB%A3%8C)
   *
   * 유저가 회원가입을 완료하였습니다
   *
   * @param options Amplitude event options.
   */
  회원가입완료(
    options?: EventOptions,
  ) {
    return this.track(new 회원가입완료(), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
