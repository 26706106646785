import React, { useEffect, useState } from 'react';

interface ICutStyleBottomSheet {
    isOpen: boolean;
    onClose: () => void;
    onFilterChange: (filters: Record<string, string[]>, tags: string[]) => void;
    initialFilters: Record<string, string[]>;
    selectedHairCutTag: string | null;
}

const filterData = {
    기장: ['스킨헤드', '짧은 머리', '눈썹 위', '눈 주변', '거지존', '장발'],
    무드: ['캐주얼', '스트릿', '미니멀', '빈티지', '레이지', '젠더리스', '깔끔', '클래식', '유니크']
};

export const CutStyleBottomSheet = ({
    isOpen,
    onClose,
    onFilterChange,
    initialFilters,
    selectedHairCutTag,
}: ICutStyleBottomSheet) => {
    const [localSelectedData, setLocalSelectedData] = useState<Record<string, string[]>>(initialFilters);

    useEffect(() => {
        setLocalSelectedData(initialFilters);
    }, [initialFilters]);

    if (!isOpen) return null;

    const handleSelectData = (category: string, item: string) => {
        setLocalSelectedData(prev => {
            const newSelection = prev[category].includes(item)
                ? prev[category].filter(i => i !== item)
                : [...prev[category], item];
            return { ...prev, [category]: newSelection };
        });
    };

    const handleReset = () => {
        setLocalSelectedData({ 기장: [], 무드: [] });
    };

    const handleApply = () => {
        const selectedTags = Object.values(localSelectedData).flat();
        const updatedTags = selectedHairCutTag
            ? [selectedHairCutTag, ...selectedTags.filter(tag => tag !== selectedHairCutTag)]
            : selectedTags;
        onFilterChange(localSelectedData, updatedTags);
        onClose();
    };

    return (
        <>
            <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={onClose} />
            <div className="fixed bottom-0 left-0 right-0 bg-white rounded-t-[20px] z-50 p-4 max-h-[80%] overflow-y-auto scrollbar-hide">
                <div className="flex justify-between items-center mb-6">
                    <div className="w-1/3" />
                    <h2 className="text-lg font-bold text-center">검색 필터</h2>
                    <button onClick={handleReset} className="text-S_Wave_Orange font-medium w-1/3 text-right">초기화</button>
                </div>
                {Object.entries(filterData).map(([category, items]) => (
                    <div key={category} className="mb-5">
                        <p className="text-sm font-bold mb-2">{category}</p>
                        <div className="flex justify-center flex-wrap gap-2">
                            {items.map((item) => (
                                <button
                                    key={item}
                                    onClick={() => handleSelectData(category, item)}
                                    className={`w-[31%] py-2  rounded-[4px] text-sm font-medium border
                                    ${localSelectedData[category].includes(item)
                                            ? ' border-S_Wave_Orange text-S_Wave_Orange'
                                            : ' border-S_Dye_1 text-S_Dye_1'
                                        }`}
                                >
                                    {item}
                                </button>
                            ))}
                        </div>
                    </div>
                ))}
                <button
                    onClick={handleApply}
                    className="w-full py-4 text-center rounded-full bg-S_Dye_Black text-white font-semibold mt-4"
                >
                    적용하기
                </button>
            </div>
        </>
    );
};