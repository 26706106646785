import React from "react";

interface IDropdownIconColor {
    color?: string;
}

export const FilterDropdownIcon: React.FC<IDropdownIconColor> = ({ color = "#ACA39A" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
            <path d="M1 0.5L5.73077 6.35573C5.7653 6.40127 5.80701 6.43757 5.85331 6.46238C5.89962 6.4872 5.94954 6.5 6 6.5C6.05046 6.5 6.10038 6.4872 6.14669 6.46238C6.19299 6.43757 6.2347 6.40127 6.26923 6.35573L11 0.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};