import React, { ChangeEvent, useEffect, useState } from 'react';
import { XBtn } from '@shared/buttons/XBtn';
import { SonigoLogo } from '@shared/imges/SonigoLogo';
import { useNavigate } from 'react-router-dom';
import { API, setAccessTokenCookie } from '../api/API';
import { ampli } from '../ampli';
import { Toast } from '@components/Toast';
import { useLoginTracking } from '@shared/hooks/usePrevTracking';
import { useUserId } from '@features/useUserId.hook';

export const Login = () => {
    const navigate = useNavigate();
    const { getLoginOrigin, setLoginOriginToOrganic } = useLoginTracking();
    const { setUserId } = useUserId();

    const [phoneNumber, setPhoneNumber] = useState<string>('');

    const [showVerification, setShowVerification] = useState<boolean>(false);
    const [verificationCode, setVerificationCode] = useState<string>('');

    const [resendDisable, setResendDisable] = useState<boolean>(false);
    const [sendCount, setSendCount] = useState<number>(0);
    const [resendText, setResendText] = useState<string>('');

    const [errorMsg, setErrorMsg] = useState<string>('');

    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>('');

    useEffect(() => {
        ampli.로그인화면진입({
            loginOrigin: getLoginOrigin(),
        });

        return () => setLoginOriginToOrganic();
    }, []);

    useEffect(() => {
        let timerId: NodeJS.Timeout;

        if (sendCount > 0) {
            setResendDisable(true);

            if (sendCount < 4) {
                setResendText('1분 후 재전송이 가능해요');
                timerId = setTimeout(() => {
                    setResendDisable(false);
                    setResendText('');
                }, 60000);
            } else {
                setResendText('30분 후 재전송이 가능해요');
                timerId = setTimeout(() => {
                    setResendDisable(false);
                    setResendText('');
                }, 1800000);
            }
        }

        return () => {
            if (timerId) clearTimeout(timerId);
        };
    }, [sendCount]);

    const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.replace(/[^0-9]/g, '');
        setPhoneNumber(value);
    };

    const handleVerificationChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.replace(/[^0-9]/g, '');
        setVerificationCode(value);
    };

    // 맨 처음 인증번호 받는 핸들러
    const handleRequestVerification = async () => {
        try {
            await API.auth.SMSRequest(phoneNumber);
        } finally {
            ampli.로그인코드전송();
            setShowVerification(true);
        }
    };

    // 재전송 버튼 핸들러
    const handleReReqVerificion = async () => {
        try {
            const success = await API.auth.SMSRequest(phoneNumber);
            console.log('sent', success);
            console.log(sendCount);
        } finally {
            ampli.로그인코드전송();
            setShowVerification(true);
            setResendDisable(true);
            setSendCount((prevCount) => prevCount + 1);
            setErrorMsg('');
        }
    };

    const handleConfirm = async () => {
        try {
            console.log(verificationCode);
            const res = await API.auth.SMSVerify(phoneNumber, verificationCode);
            console.log(res);
            setUserId(res.data.sonigoId);

            if ('accessToken' in res.data) {
                // accessToken 쿠키, 로컬, query에 각각 저장
                ampli.로그인완료();
                ampli.identify(res.data.userId, {
                    ['sonigo ID']: res.data.sonigoId,
                });

                setAccessTokenCookie(res.data.accessToken);
                navigate('/');
            } else if ('phoneNumberToken' in res.data) {
                navigate('/nickname', {
                    state: {
                        phoneNumberToken: res.data.phoneNumberToken,
                    },
                });

                // event 전송
                ampli.회원가입완료();
                ampli.identify(res.data.userId, {
                    ['sonigo ID']: res.data.sonigoId,
                });
            }
        } catch (error) {
            setErrorMsg('인증번호가 일치하지 않아요.');
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const toast = params.get('toast');
        if (toast) {
            setToastMessage(toast);
            setShowToast(true);
        }
    }, [location, navigate]);

    const handleXBtn = () => {
        const params = new URLSearchParams(location.search);
        const where = params.get('where');
        if (where === 'detail') {
            navigate(-1);
        } else {
            navigate('/');
        }
    };

    return (
        <div className="flex flex-col items-center w-full h-[lvh]">
            {/* 로그인 헤더 */}
            <div className="flex justify-between items-center w-full py-[14px] px-[17px]">
                <div className="flex-grow text-center ml-5">
                    <p className="text-black text-lg font-semibold">
                        로그인 / 회원가입
                    </p>
                </div>
                <button onClick={handleXBtn}>
                    <XBtn />
                </button>
            </div>
            {/* 로그인 바디 */}
            <div className="mt-[21px] mb-[15px]">
                <SonigoLogo />
            </div>
            <p className="text-S_Dye_5 text-xl font-medium">
                간편하게 SMS 로그인 후
            </p>
            <p className="text-S_Dye_Black text-xl font-semibold">
                나만의 스타일을 찾아 보세요.
            </p>
            {/* 인증 */}
            <div className="flex flex-col w-full px-4">
                {showVerification && (
                    <>
                        <div className="flex justify-between text-sm mt-8 mb-2 ml-[3px]">
                            <p className=" text-S_Dye_Black font-semibold">
                                인증 번호
                            </p>
                            <p className="text-S_Wave_Orange font-medium">
                                {errorMsg}
                            </p>
                        </div>
                        <div className="flex items-center w-full">
                            <div className="flex-grow">
                                <label htmlFor="phoneAuthConfirm">
                                    <input
                                        type="tel"
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        name="phoneAuthConfirm"
                                        id="phoneAhthConfirm"
                                        className={`bg-S_Dye_White w-full py-[15px] px-[19px] rounded-[4px] text-S_Dye_Black font-medium
                                ${verificationCode ? 'border-[1.5px] border-S_Wave_Orange' : 'border-none'}
                                focus:outline-none
                                `}
                                        value={verificationCode}
                                        onChange={handleVerificationChange}
                                        placeholder="인증번호를 입력해주세요."
                                    />
                                </label>
                            </div>
                            <button
                                className="bg-S_Dye_5 py-[15px] px-[21px] rounded-[4px] ml-2 whitespace-nowrap"
                                onClick={handleConfirm}
                            >
                                <p className="text-S_White font-semibold text-sm">
                                    확인
                                </p>
                            </button>
                        </div>
                    </>
                )}
                {/* 인증번호 받기 */}
                <div className="flex justify-between text-sm mt-8 mb-2 ml-[3px]">
                    <p className=" text-S_Dye_Black font-semibold">
                        휴대폰 번호
                    </p>
                    <p className="text-S_Wave_Orange font-medium">
                        {resendText}
                    </p>
                </div>
                <div className="flex items-center">
                    {showVerification ? (
                        <>
                            <div className="flex-grow">
                                <label htmlFor="phoneAuthReq">
                                    <input
                                        type="tel"
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        name="phoneAuthReq"
                                        id="phoneAhthReq"
                                        className={`bg-S_Dye_White w-full py-[15px] px-[19px] rounded-[4px] text-S_Dye_Black font-medium
                                ${phoneNumber ? 'border-[1.5px] border-S_Wave_Orange' : 'border-none'}
                                focus:outline-none
                                `}
                                        value={phoneNumber}
                                        onChange={handlePhoneChange}
                                    />
                                </label>
                            </div>
                            <button
                                className={`w-[40%] py-[15px] px-3 rounded-[4px] ml-2 whitespace-nowrap
                                ${resendDisable ? 'bg-[#CCC]' : 'bg-S_Dye_5'}
                                `}
                                onClick={handleReReqVerificion}
                                disabled={resendDisable}
                            >
                                <p className="text-S_White font-semibold text-sm">
                                    재전송
                                </p>
                            </button>
                        </>
                    ) : (
                        <>
                            <div className="flex-grow">
                                <label htmlFor="phoneAuthReq">
                                    <input
                                        type="tel"
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        name="phoneAuthReq"
                                        id="phoneAhthReq"
                                        className={`bg-S_Dye_White w-full py-[15px] px-[19px] rounded-[4px] text-S_Dye_Black font-medium
                                ${phoneNumber ? 'border-[1.5px] border-S_Wave_Orange' : 'border-none'}
                                focus:outline-none
                                `}
                                        value={phoneNumber}
                                        onChange={handlePhoneChange}
                                        placeholder="01012345678"
                                    />
                                </label>
                            </div>
                            <button
                                className="bg-S_Dye_5 py-[15px] px-3 rounded-[4px] ml-2 whitespace-nowrap"
                                onClick={handleRequestVerification}
                            >
                                <p className="text-S_White font-semibold text-sm">
                                    인증번호 받기
                                </p>
                            </button>
                        </>
                    )}
                </div>
            </div>
            <div className="absolute w-full h-[50px] left-0 right-0 bottom-20">
                {showToast && (
                    <Toast
                        message={toastMessage}
                        duration={3000}
                        onClose={() => setShowToast(false)}
                    />
                )}
            </div>
        </div>
    );
};
