import React from 'react';
import { InstagramVisitBtn } from '@shared/buttons/InstagramVisitBtn';
import { ShareIcon } from '@shared/icons/ShareIcon';
import { BookmarkIcon } from '@shared/icons/BookmarkIcon';

interface ActionToolBarProps {
    isBookmarked: boolean;
    onBookmarkToggle: () => void;
    shareUrl: string;
    shareTitle: string;
    postId: string | undefined;
    shareCallback?: () => void;
    instagramUrl: string;
}

export const ActionToolBar: React.FC<ActionToolBarProps> = ({
    isBookmarked,
    onBookmarkToggle,
    shareUrl,
    shareTitle,
    postId,
    shareCallback,
    instagramUrl,
}) => {
    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: shareTitle,
                    url: shareUrl,
                });
                console.log('Content shared successfully');
                if (shareCallback) {
                    shareCallback();
                }
            } catch (error) {
                console.log('Error sharing content:', error);
            }
        } else {
            console.log('Web Share API not supported');
        }
    };

    return (
        <div className="flex justify-center items-center gap-[10px] py-[12px]">
            <InstagramVisitBtn instagramUrl={instagramUrl} />
            <button
                className="py-2 px-2 bg-S_Dye_White rounded-[4px]"
                onClick={handleShare}
            >
                <ShareIcon />
            </button>
            <button
                className="py-2 px-2 bg-S_Dye_White rounded-[4px]"
                onClick={onBookmarkToggle}
                disabled={!postId}
            >
                <BookmarkIcon
                    fill={isBookmarked ? '#FF671F' : '#F2F1F0'}
                    stroke={isBookmarked ? '#FF671F' : '#ACA39A'}
                />
            </button>
        </div>
    );
};
