import React from 'react';
import { BookmarkBtn } from '@shared/buttons/BookmarkBtn';
import { HomeBtn } from '@shared/buttons/HomeBtn';
import { SearchBtn } from '@shared/buttons/SearchBtn';
import { UploadBtn } from '@shared/buttons/UploadBtn';
import { useNavigate } from 'react-router-dom';

interface BottomBarProps {
    activePage?: string;
    onClick?: () => void;
}

export const BottomBar: React.FC<BottomBarProps> = ({ activePage }) => {
    const navigate = useNavigate();

    return (
        <div className="flex justify-around items-center bg-S_White py-3 border-t border-S_Dye_1">
            <HomeBtn
                isActive={activePage === '/'}
                onClick={() => navigate('/')}
            />
            <SearchBtn
                isActive={activePage === '/search' || activePage === '/research-detail'}
                onClick={() => navigate('/search')}
            />
            <UploadBtn onClick={() => navigate('/upload')} />
            <BookmarkBtn
                isActive={activePage === 'bookmark'}
                onClick={() => navigate('/bookmark')}
            />
        </div>
    );
};
