import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { DetailSelectBar } from '@components/DetailSelectBar';
import { PrevTopBar } from '@components/PrevTopBar';
import { ActionToolBar } from '@components/ActionToolBar';
import { CloseIcon } from '@shared/icons/CloseIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { API, IFeedResponse, IPost, IPostDetailResponse } from '../api/API';
import InfiniteScroll from '@shared/utils/InfiniteScroll';
import Masonry from 'react-masonry-css';
import Lottie from 'lottie-react';
import scissor from '@shared/icons/scissors.json';
import { useDepthTracking } from '@shared/hooks/useDepthTracking';
import { usePrevTags } from '@shared/hooks/usePrevTags';
import { ampli } from '../ampli';
import { LoginReferer, useLoginTracking, usePrevTrackingNavigate } from '@shared/hooks/usePrevTracking';
import { usePrevFeedId } from '@shared/hooks/usePrevFeedId';

interface ImageSkeletonProps {
    showScissors?: boolean;
    className?: string;
    height?: string;
}

const ImageSkeleton: React.FC<ImageSkeletonProps> = ({
    showScissors = true,
    className = 'w-[calc(50vw - 8px)] aspect-[3/4] rounded-lg',
}) => {
    return (
        <div
            className={`aspect-square relative bg-gray-200 animate-pulse rounded-lg ${className} `}
        >
            {showScissors && (
                <div className="absolute inset-0 flex justify-center items-center">
                    <div className="w-12 h-12">
                        <Lottie
                            animationData={scissor}
                            loop={true}
                            autoplay={true}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export const Detail = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const selectedTagsRef = useRef<HTMLDivElement>(null);
    const { goForward, currentDepth, prevDepth } = useDepthTracking('main');

    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const { appendTags, popTags } = usePrevTags('main');
    const [isBookmarked, setIsBookmarked] = useState<boolean>(false);
    const [isBookmarking, setIsBookmarking] = useState<boolean>(false);
    const [postInfo, setPostInfo] = useState<IPost | null>(null);

    const [postRelatedImg, setPostRelatedImg] = useState<IPost[]>([]);

    const [relatedFeedId, setRelatedFeedId] = useState<string>('');

    const [loading, setLoading] = useState<boolean>(true);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1);
    const [detailLoaded, setDetailLoaded] = useState<boolean>(false);
    const [isForwardNavigated, setIsForwardNavigated] = useState<boolean>(false);
    const [isNavigated, setIsNavigated] = useState<boolean>(false);
    const [instagramUrl, setInstagramUrl] = useState<string>('https://instagram.com');
    const [isInitial, setIsInitial] = useState<boolean>(true);

    const { clickedPost } = location.state || {};
    const { getPrev } = usePrevTrackingNavigate();
    const { appendFeedId, popFeedId } = usePrevFeedId('main');
    const { setLoginOriginToOrganic } = useLoginTracking();

    const loadMore = useCallback(() => {
        if (!loading && hasMore) {
            setPage((prevPage) => prevPage + 1);
        }
    }, [loading, hasMore]);

    const toggleNavigated = () => {
        setIsNavigated(!isNavigated);
    }

    const breakpointColumnsObj = useMemo(
        () => ({
            default: 2,
            1100: 2,
            700: 2,
            500: 2,
        }),
        [],
    );

    useEffect(() => {
        const d = currentDepth();
        const p = prevDepth();
        console.log('DEPTH', d);
        console.log('PREV', p);

        if (prevDepth && d > p) {
            setIsForwardNavigated(true);
            toggleNavigated()
        } else {
            setIsForwardNavigated(false);
            toggleNavigated()
        }
    }, [window.location.pathname]);

    useEffect(() => {
        if (clickedPost) {
            if (postInfo?.postId !== clickedPost.postId) {
                console.log('replace');
                setDetailLoaded(false);
            }
            console.log('fetch');

            setPostInfo(clickedPost);
        }
    }, [clickedPost]);

    useEffect(() => {
        if (!isForwardNavigated && !isInitial) {
            console.log('팝!!!!!!!!!!!!!!!!!!!!');
            const prevTags = popTags();
            console.log('호출출출', prevTags);
            setSelectedTags(prevTags);

            const prevFeedId = popFeedId();
            console.log('피드피드피드피드피드피드피드', prevFeedId);
            setRelatedFeedId(prevFeedId);
        }
    }, [isNavigated, isForwardNavigated]);

    useEffect(() => {
        if (!detailLoaded && postInfo) {
            fetchCurrentPostDetail();
        }
    }, [postInfo, detailLoaded]);


    const fetchCurrentPostDetail = async () => {
        if (!clickedPost || !clickedPost.postId) return;
        let res: AxiosResponse<IPostDetailResponse>;
        if (API.isAuthorized()) {
            res = await API.post.detail(clickedPost.postId);
        } else {
            res = await API.postNoAuth.detail(clickedPost.postId);
        }

        setPostInfo({
            postId: postInfo.postId,
            imageUrl: postInfo.imageUrl,
            uploaderSonigoId: postInfo.uploaderSonigoId,
            tags: res.data.result.tags,
        });

        if (res.data.result.hasBeenSaved) {
            setIsBookmarked(res.data.result.hasBeenSaved);
        }

        if (res.data.result.externalLink) {
            setInstagramUrl(res.data.result.externalLink);
        }

        setDetailLoaded(true);
    };

    useEffect(() => {
        setPage(1)
        setPostRelatedImg([]);
    }, [selectedTags]);

    useEffect(() => {
        fetchRelatedImages()
    }, [page]);

    const fetchRelatedImages = async () => {
        console.log('호출');
        if (!clickedPost || !clickedPost.postId) return;

        setLoading(true);
        setIsInitial(false);

        try {
            let res: AxiosResponse<IFeedResponse>;
            try {
                console.log("asdjhbaskjdbas", relatedFeedId, selectedTags, page)
                if (relatedFeedId) {
                    if (API.isAuthorized()) {
                        res = await API.feed.related.next(
                            clickedPost.postId,
                            page,
                            relatedFeedId,
                            selectedTags,
                        );
                    } else {
                        res = await API.feed.relatedNoAuth.next(
                            clickedPost.postId,
                            page,
                            relatedFeedId,
                            selectedTags,
                        );
                    }
                } else if (relatedFeedId === '') {
                        if (API.isAuthorized()) {
                            res = await API.feed.related.get(
                                clickedPost.postId,
                                selectedTags,
                            );
                        } else {
                            res = await API.feed.relatedNoAuth.get(
                                clickedPost.postId,
                                selectedTags,
                            );
                        }
                } else {
                    if (API.isAuthorized()) {
                        res = await API.feed.related.next(
                            clickedPost.postId,
                            page,
                            relatedFeedId,
                            selectedTags,
                        );
                    } else {
                        res = await API.feed.relatedNoAuth.next(
                            clickedPost.postId,
                            page,
                            relatedFeedId,
                            selectedTags,
                        );
                    }
                }
            } catch (e) {
                console.log(e);
            }

            if (!res.data?.result?.posts) {
                setPostRelatedImg([]);
            }

            if (res.data.statusCode === 200 && res.data.message === 'success') {
                const { posts, page } = res.data.result;

                setPostRelatedImg((prevData) => {
                    const uniquePosts = Array.from(
                        new Set(
                            [...prevData, ...posts].map((post) => post.postId),
                        ),
                    ).map((id) =>
                        [...prevData, ...posts].find(
                            (post) => post.postId === id,
                        ),
                    );
                    return uniquePosts as IPost[];
                });

                if (page === 1) {
                    setRelatedFeedId(res.data.result.feedId);
                }

                setPage(page);
                setHasMore(posts.length > 0);
            } else {
                throw new Error('API response was not successful');
            }
        } catch (error) {
            console.error('Error fetching images:', error);
            setHasMore(false);
        } finally {
            setLoading(false);
        }
    };

    // 태그 선택/삭제 토글
    const handleSelect = async (tag: string) => {
        if (selectedTags && selectedTags.includes(tag)) {
            setSelectedTags((prevTags) =>
                prevTags.filter((selectedTag) => selectedTag !== tag),
            );
            setRelatedFeedId('');
        } else {
            setSelectedTags((prevTags) => [...prevTags, tag]);
            setRelatedFeedId('');
            setPage(1);
        }
    };

    // 태그 삭제
    const handleRemoveTag = async (tagToRemove: string) => {
        setSelectedTags((prevTags) =>
            prevTags.filter((tag) => tag !== tagToRemove),
        );
        setRelatedFeedId('');
        setPage(1);

        try {
            if (API.isAuthorized()) {
                await API.feed.kill(relatedFeedId);
            } else {
                await API.feed.killNoAuth(relatedFeedId);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const { logLoginOrigin } = useLoginTracking()

    // 사진 저장 토글 버튼
    const handleBookmarkToggle = async () => {
        if (!API.isAuthorized()) {
            console.log('로그인 안됨');
            logLoginOrigin(LoginReferer.SAVE_BUTTON)

            navigate('/login?toast=로그인 후 사용이 가능해요.&where=detail');
            return;
        }

        if (isBookmarking) return;

        setIsBookmarking(true);
        try {
            if (!isBookmarked) {
                setIsBookmarked(!isBookmarked);
                await API.bookmark.save(postInfo?.postId);
                ampli.포스트저장({
                    depth: currentDepth(),
                    origin: 'organic', // This should be one of "organic", "search", "home", "save"
                    ownedBy: postInfo.uploaderSonigoId,
                    postId: postInfo.postId,
                    tags: postInfo.tags,
                });
                console.log('저장 성공');
            } else {
                setIsBookmarked(!isBookmarked);
                await API.bookmark.unsave(postInfo?.postId);
                ampli.포스트저장취소({
                    depth: currentDepth(),
                    origin: 'organic', // This should be one of "organic", "search", "home", "save"
                    ownedBy: postInfo.uploaderSonigoId,
                    postId: postInfo.postId,
                    tags: postInfo.tags,
                });
                console.log('저장 해제 성공');
            }
        } finally {
            setIsBookmarking(false);
        }
    };

    const handleImgClick = (postId: string) => {
        const clickedPost = postRelatedImg.find(
            (post) => post.postId === postId,
        );

        let tags: string[][];
        console.log('selected', selectedTags);

        console.log('prevSelectedTagsImgClick', tags);

        console.log('prevSelectedTagsImgClick', tags);

        goForward()
        appendTags(selectedTags)
        appendFeedId(relatedFeedId)
        ampli.포스트자세히보기썸네일클릭({
            depth: currentDepth(),
            origin: getPrev(), // This should be one of "organic", "search", "home", "save"
            ownedBy: clickedPost.uploaderSonigoId,
            postId: clickedPost.postId,
            tags: clickedPost.tags,
        })
        setPage(1);
        setSelectedTags([]);
        setPostRelatedImg([]);
        setRelatedFeedId('');
        setIsBookmarked(isBookmarked);
        setIsInitial(false);

        navigate(`/detail/${postId}`, {
            state: {
                clickedPost: clickedPost,
                isBookmarked: isBookmarked,
            },
        });

    };

    useEffect(() => {
        fetchRelatedImages();
        setLoginOriginToOrganic();
        setIsInitial(true);
    }, []);

    useEffect(() => {
        if (!selectedTags || selectedTags?.length === 0) {
            window.scrollTo({ top: 0, behavior: 'auto' });
        }
    }, [location.pathname]);

    useEffect(() => {
        if (selectedTags?.length > 0) {
            selectedTagsRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [selectedTags]);

    const onPrev = () => {
        setIsInitial(false);
    }

    return (
        <>
            <PrevTopBar feedId={relatedFeedId} beforeNavigate={onPrev} depthTrackingName="main" />
            {postInfo && (
                <div>
                    <img
                        src={postInfo.imageUrl}
                        alt=""
                        className="w-full object-cover mt-12"
                    />
                    <div className="w-full px-2 pb-2">
                        <div className="p-2">
                            <DetailSelectBar
                                key={postInfo.tags?.join('')}
                                onSelect={handleSelect}
                                postInfoTags={
                                    postInfo.tags ? postInfo.tags : []
                                }
                            />
                            <ActionToolBar
                                isBookmarked={isBookmarked}
                                onBookmarkToggle={handleBookmarkToggle}
                                shareUrl={window.location.href}
                                shareTitle="공유"
                                postId={postInfo.postId}
                                shareCallback={() => {
                                    ampli.포스트공유시도({
                                        depth: currentDepth(),
                                        origin: getPrev(),
                                        ownedBy: postInfo.uploaderSonigoId,
                                        postId: postInfo.postId,
                                        tags: postInfo.tags,
                                    });
                                }}
                                instagramUrl={instagramUrl}
                            />
                        </div>
                        <div className="max-w-[500px] w-full h-[6px] bg-S_Dye_White absolute left-1/2 transform -translate-x-1/2"></div>
                    </div>
                    <div
                        className="w-full px-2 mb-2 scroll-mt-[55px]"
                    >
                        <p className="ml-3 mt-3 font-bold text-lg text-S_Dye_Black">
                            유사한 스타일
                        </p>
                    </div>
                    <div
                        ref={selectedTagsRef}
                        className={
                            'w-full px-2 flex flex-wrap gap-2 sticky top-10 z-40 bg-white ' +
                            (selectedTags?.length &&
                                selectedTags.length > 0 &&
                                'pb-2 mb-1')
                        }
                    >
                        {selectedTags &&
                            selectedTags.map((tag) => (
                                <span
                                    key={tag}
                                    className={`py-2 px-[14px] border-[0.5px] border-S_Dye_White rounded-full
                    bg-S_WO_20 text-sm font-medium text-S_Wave_Orange shadow-[inset_0px_-2px_1px_0px_#FFBE9F]
                    flex items-center first:ml-3 scroll-mt-16`}
                                >
                                    {tag}
                                    <button
                                        onClick={() => handleRemoveTag(tag)}
                                        className="ml-[6px]"
                                    >
                                        <CloseIcon />
                                    </button>
                                </span>
                            ))}
                    </div>
                    <div className="w-full px-3">
                        <InfiniteScroll
                            loadMore={loadMore}
                            hasMore={hasMore}
                            isLoading={loading}
                        >
                            <Masonry
                                breakpointCols={breakpointColumnsObj}
                                className="my-masonry-grid"
                                columnClassName="my-masonry-grid_column"
                            >
                                {postRelatedImg.map((d) => (
                                    <div
                                        key={d.postId}
                                        className="mb-4 break-inside-avoid content-loaded"
                                    >
                                        <img
                                            src={d.imageUrl}
                                            alt=""
                                            className="w-full object-cover rounded-lg cursor-pointer"
                                            style={{ width: '50vw', minWidth: '160px', minHeight: '250px'}}
                                            onClick={() =>
                                                handleImgClick(d.postId)
                                            }
                                            loading={'lazy'}
                                        />
                                        <div className="mt-1 px-1 mb-[26px]">
                                            <p className="font-semibold text-S_Dye_Black truncate">
                                                @{d.uploaderSonigoId.length > 15 ? d.uploaderSonigoId.slice(0, 15) + '..' : d.uploaderSonigoId}
                                            </p>
                                            <p className="text-S_Dye_1 font-medium break-words">
                                                {d.tags.map((tag, index) => (
                                                    <span key={index}>
                                                        #{tag}&nbsp;
                                                    </span>
                                                ))}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </Masonry>
                        </InfiniteScroll>
                    </div>
                    {loading && (
                        <div className="col-span-full w-full pb-3">
                            <div className="flex justify-center mb-4">
                                <div className="w-12 h-12">
                                    <Lottie
                                        animationData={scissor}
                                        loop={true}
                                        autoplay={true}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-center gap-2 ">
                                {Array.from({ length: 4 }).map((_, index) => (
                                    <ImageSkeleton
                                        key={`skeleton-${index}`}
                                        showScissors={false}
                                        className={
                                            postRelatedImg.length === 0
                                                ? 'w-[calc(46vw-8px)] sm:w-[240px] h-[200px] sm:h-[300px] aspect-[3/4] rounded-lg'
                                                : ''
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
