import { tw } from '@shared/model/Tailwindest';

export const loginModalTransiton = tw.style({
    transitionProperty: 'transition-opacity',
    transitionDuration: 'duration-200',
    transitionTimingFunction: 'ease-in-out',
    position: 'fixed',
    zIndex: 'z-50',
    inset: 'inset-0',
    backgroundColor: 'bg-P3_bg_cloudy',
});

export const loginModalLayout = tw.style({
    display: 'flex',
    alignItems: 'items-center',
    justifyContent: 'justify-center',
    position: 'fixed',
    width: 'w-[45%]',
    top: 'top-0',
    right: 'right-0',
    bottom: 'bottom-0',
    zIndex: 'z-40',
});

export const loginModalContainer = tw.style({
    display: 'flex',
    flexDirection: 'flex-col',
    justifyContent: 'justify-start',
    alignItems: 'items-center',
    minWidth: 'min-w-[100%]',
    height: 'h-[100%]',
    borderWidth: 'border',
    borderLeftRadius: 'rounded-l-lg',
    overflow: 'overflow-auto',
    backgroundColor: 'bg-white',
    zIndex: 'z-50',
    paddingX: 'px-4',
    paddingTop: 'pt-[61px]',
    paddingBottom: 'pb-3',
});
