import { useEffect } from 'react';

export const usePrevTags = (name: string) => {
    const NAME = `${name}_tags_prev`;

    useEffect(() => {
        const tags = sessionStorage.getItem(NAME);
        if (!tags) {
            sessionStorage.setItem(NAME, '[]');
        }
    }, []);

    const appendTags = (tags: string[]) => {
        console.log('TATAGS', tags);
        const i = sessionStorage.getItem(NAME);
        const t = JSON.parse(i || '[]');
        t.push(tags);

        sessionStorage.setItem(NAME, JSON.stringify(t));
    };

    const popTags = () => {
        let tags: string[] = [];
        const i = sessionStorage.getItem(NAME);
        const t = JSON.parse(i || '[]');
        const a = t.pop();
        if (a) {
            tags = a;
        }
        sessionStorage.setItem(NAME, JSON.stringify(t));

        return tags;
    };

    const clearTags = () => {
        sessionStorage.setItem(NAME, '[]');
    };

    return {
        appendTags,
        popTags,
        clearTags,
    };
};