import React from "react";

export const ShareIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
    >
      <path
        d="M7.93265 15.0488L10.8068 17.7171C10.9831 17.8857 11.2036 18.0084 11.4464 18.0731C11.6892 18.1378 11.9459 18.1422 12.1911 18.086C12.4379 18.0325 12.6657 17.9207 12.8527 17.7613C13.0397 17.602 13.1797 17.4004 13.259 17.176L17.9789 3.98195C18.0774 3.73473 18.0978 3.46633 18.0375 3.20866C17.9772 2.95098 17.8389 2.71486 17.6389 2.52837C17.439 2.34188 17.1858 2.21286 16.9095 2.15665C16.6333 2.10043 16.3455 2.11939 16.0804 2.21126L1.93387 6.61339C1.68502 6.69267 1.46339 6.83248 1.29187 7.01839C1.12035 7.2043 1.00518 7.42956 0.958248 7.67088C0.909809 7.89036 0.920592 8.11763 0.989629 8.33226C1.05867 8.54689 1.1838 8.74216 1.35377 8.90053L4.96622 12.2698L4.84756 16.5366L7.93265 15.0488Z"
        stroke="#ACA39A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5569 2.48145L4.96606 12.2694"
        stroke="#ACA39A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
