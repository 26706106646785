import { NavigateFunction, useNavigate } from 'react-router-dom';

export const usePrevTrackingNavigate = () => {
    const NAME = `detail_prev`;

    const getPrev = () => {
        const p = sessionStorage.getItem(NAME);
        if (p) {
            return p;
        }

        return Referer.ORGANIC;
    };

    const nav = useNavigate();

    const refNavigate = (where: RefererType): NavigateFunction => {
        sessionStorage.setItem(NAME, where);
        return nav;
    };

    const setOrganic = () => {
        sessionStorage.setItem(NAME, Referer.ORGANIC);
    };

    return {
        refNavigate,
        getPrev,
        setOrganic,
    };
};

export const Referer = {
    BOOKMARK: 'save',
    HOME: 'home',
    SEARCH: 'search',
    ORGANIC: 'organic',
};
export type RefererType = (typeof Referer)[keyof typeof Referer];

export const useLoginTracking = () => {
    const NAME = `login_origin`;

    const getLoginOrigin = () => {
        const p = sessionStorage.getItem(NAME);
        if (p) {
            return p;
        }

        return Referer.ORGANIC;
    };

    const logLoginOrigin = (where: LoginRefererType) => {
        sessionStorage.setItem(NAME, where);
    };

    const setLoginOriginToOrganic = () => {
        sessionStorage.setItem(NAME, Referer.ORGANIC);
    };

    return {
        getLoginOrigin,
        logLoginOrigin,
        setLoginOriginToOrganic,
    };
};

export const LoginReferer = {
    SAVE_TAB: 'saveTab',
    ICON: 'icon',
    SAVE_BUTTON: 'saveButton',
    ORGANIC: 'organic',
};
export type LoginRefererType = (typeof LoginReferer)[keyof typeof LoginReferer];
