import React, { useEffect, useRef, useCallback } from 'react';

interface InfiniteScrollProps {
    loadMore: () => void;
    hasMore: boolean;
    isLoading: boolean;
    children: React.ReactNode;
}

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
    loadMore,
    hasMore,
    isLoading,
    children
}) => {
    const observer = useRef<IntersectionObserver | null>(null);
    const lastElementRef = useCallback((node: HTMLDivElement | null) => {
        if (isLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                loadMore();
            }
        });
        if (node) observer.current.observe(node);
    }, [isLoading, hasMore, loadMore]);

    useEffect(() => {
        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, []);

    return (
        <>
            {children}
            <div ref={lastElementRef} />
        </>
    );
};

export default InfiniteScroll;