import React from 'react';
import { ampli } from '../../ampli';

interface InstagramVisitBtnProps {
    instagramUrl: string;
}

export const InstagramVisitBtn = ({instagramUrl}: InstagramVisitBtnProps) => {
    const handleBtn = () => {
        ampli.인스타그램방문버튼클릭({
            url: instagramUrl,
        });
        window.open(
            instagramUrl,
            '_blank',
            'noopener,noreferrer',
        );
    };
    return (
        <button className="bg-S_Dye_5 w-full rounded-[4px]" onClick={handleBtn}>
            <p className="font-medium text-S_White text-sm py-[11px]">
                인스타그램 방문
            </p>
        </button>
    );
};
