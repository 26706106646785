import { createTools, type Tailwindest } from 'tailwindest';

type TailwindCustom = Tailwindest<{
    color:
        | 'S_White'
        | 'S_Gray'
        | 'S_Dye_Black'
        | 'S_Dye_White'
        | 'S_Dye_1'
        | 'S_Dye_5'
        | 'S_Wave_Orange'
        | 'S_WO_20'
        | 'P3_bg_cloudy';
    screens: 'iphone' | 'ipad' | 'mac';
}>;

const tw = createTools<TailwindCustom>();

export { tw };
