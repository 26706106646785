import { useEffect } from 'react';

export const useDepthTracking = (name: string) => {
    const NAME = `${name}_depth`;
    const PREV_NAME = `${name}_depth_prev`;

    useEffect(() => {
        const depth = parseInt(sessionStorage.getItem(NAME));
        if (!depth) {
            sessionStorage.setItem(NAME, '0');
            sessionStorage.setItem(PREV_NAME, '0');
        }
    }, []);

    const goForward = () => {
        const depth = parseInt(sessionStorage.getItem(NAME));
        if (depth) {
            sessionStorage.setItem(PREV_NAME, `${depth}`);
            sessionStorage.setItem(NAME, `${depth + 1}`);
            return;
        }

        sessionStorage.setItem(NAME, '1');
    };

    const goBack = () => {
        const depth = parseInt(sessionStorage.getItem(NAME));
        if (depth) {
            sessionStorage.setItem(PREV_NAME, `${depth}`);
            sessionStorage.setItem(NAME, `${depth - 1}`);
            return;
        }
    };

    const currentDepth = () => {
        return parseInt(sessionStorage.getItem(NAME));
    };

    const prevDepth = () => {
        const depth = parseInt(sessionStorage.getItem(PREV_NAME));
        if (depth) {
            return depth;
        }

        return null;
    };

    const clearDepth = () => {
        sessionStorage.setItem(NAME, '0');
        sessionStorage.setItem(PREV_NAME, '0');
        return;
    };

    return {
        goForward,
        goBack,
        currentDepth,
        prevDepth,
        clearDepth,
    };
};
