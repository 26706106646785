import { useQuery, useQueryClient } from '@tanstack/react-query';

export const useUserId = () => {
    const queryClient = useQueryClient();

    const { data: userId } = useQuery({
        queryKey: ['userId'],
        initialData: () => localStorage.getItem('userId'),
    });

    const setUserId = (userId: string) => {
        queryClient.setQueryData(['userId'], userId);
        localStorage.setItem('userId', userId);
    };

    return { userId, setUserId };
};
