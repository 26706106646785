import React from "react";

export const DropdownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="9"
      viewBox="0 0 15 9"
      fill="none"
    >
      <path
        d="M1.57629 1.96985L7.25322 7.82558C7.29465 7.87112 7.3447 7.90742 7.40027 7.93223C7.45584 7.95705 7.51574 7.96985 7.57629 7.96985C7.63684 7.96985 7.69675 7.95705 7.75232 7.93223C7.80789 7.90742 7.85793 7.87112 7.89937 7.82558L13.5763 1.96985"
        stroke="#232222"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
