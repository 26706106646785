import React, { useRef } from 'react';
import { PrevIcon } from '@shared/icons/PrevIcon';
import { useNavigate } from 'react-router-dom';
import { API } from '../api/API';
import { useDepthTracking } from '@shared/hooks/useDepthTracking';

interface PrevTopBarProps {
    feedId: string;
    depthTrackingName: string;
    beforeNavigate?: () => void;
}

export const PrevTopBar: React.FC<PrevTopBarProps> = ({
    feedId,
    depthTrackingName,
    beforeNavigate,
}) => {
    const navigate = useNavigate();
    const { goBack } = useDepthTracking(depthTrackingName);
    const lastClickTime = useRef(0);

    const handleOnclick = () => {
        const now = Date.now();
        if (now - lastClickTime.current < 2000) {
            return;
        }
        lastClickTime.current = now;
        if (!feedId) {
            goBack();
            navigate('/');
            return;
        }
        try {
            if (!API.isAuthorized()) {
                API.feed.killNoAuth(feedId);
            } else {
                API.feed.kill(feedId);
            }
        } catch (e) {
            console.log(e);
        }

        goBack();
        beforeNavigate && beforeNavigate();
        navigate(-1);
    };

    return (
        <>
            <div className="max-w-[500px] w-full py-1 fixed left-1/2 transform -translate-x-1/2 bg-white z-30">
                <div className="flex items-center">
                    <button onClick={handleOnclick} className="px-6 py-3">
                        <PrevIcon />
                    </button>
                </div>
                <div className="flex-grow"></div>
            </div>
        </>
    );
};
