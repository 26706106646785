import { useEffect } from 'react';

export const usePrevFeedId = (name: string) => {
    const NAME = `${name}_feed_prev`;

    useEffect(() => {
        const tags = sessionStorage.getItem(NAME);
        if (!tags) {
            sessionStorage.setItem(NAME, '[]');
        }
    }, []);

    const appendFeedId = (tags: string) => {
        console.log('TATAGS', tags);
        const i = sessionStorage.getItem(NAME);
        const t = JSON.parse(i || '[]');
        t.push(tags);

        sessionStorage.setItem(NAME, JSON.stringify(t));
    };

    const popFeedId = () => {
        let tags = '';
        const i = sessionStorage.getItem(NAME);
        const t = JSON.parse(i || '[]');
        const a = t.pop();
        if (a) {
            tags = a;
        }
        sessionStorage.setItem(NAME, JSON.stringify(t));

        return tags;
    };

    const clearFeedIds = () => {
        sessionStorage.setItem(NAME, '[]');
    };

    return {
        appendFeedId,
        popFeedId,
        clearFeedIds,
    };
};
