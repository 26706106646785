import React from "react";

export const RefreshIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
      >
        <rect width="35" height="35" rx="4" fill="#433A32" />
        <path
          d="M21.5002 20.3333L23.5002 19.75L24.0002 22.0833"
          stroke="white"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.5001 19.75C23.0559 21.2184 22.2492 22.5005 21.1814 23.4348C20.1137 24.3691 18.8327 24.9137 17.5001 25C16.2682 25.0003 15.0661 24.5581 14.0571 23.7337C13.0481 22.9092 12.281 21.7424 11.8601 20.3917"
          stroke="white"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5 15.6667L11.5 16.25L11 13.9167"
          stroke="white"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5 16.25C12.34 13.5667 14.92 11 17.5 11C18.7379 11.0041 19.9444 11.4547 20.9541 12.2902C21.9639 13.1257 22.7274 14.3051 23.14 15.6667"
          stroke="white"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
