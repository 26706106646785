import React, { useEffect, useState } from 'react';

interface ToastProps {
    message: React.ReactNode;
    duration?: number;
    onClose: () => void;
}

export const Toast: React.FC<ToastProps> = ({
    message,
    duration = 3000,
    onClose,
}) => {
    const [isVisible, setIsVisible] = useState<boolean>(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
            onClose();
        }, duration);

        return () => clearTimeout(timer);
    }, [duration, onClose]);

    if (!isVisible) return null;

    return (
        <div className="flex justify-center items-center text-center">
            <div className="w-[90%] px-4 py-2 bg-S_Dye_5 rounded-[4px]">
                <p className="text-S_White font-semibold">{message}</p>
            </div>
        </div>
    );
};
