import React from 'react';

interface DetailSelectBarProps {
    onSelect: (tag: string) => void;
    postInfoTags: string[];
}

export const DetailSelectBar: React.FC<DetailSelectBarProps> = ({
    onSelect,
    postInfoTags,
}) => {
    // 첫 번째 인덱스로 '#' 강제삽입, 다른 컴포넌트에서 재사용 하려면 추후에 바꿔야함
    const tagsWithHash = ['#', ...postInfoTags.filter((tag) => tag !== '#')];
    return (
        <div className="flex gap-[6px] mt-1 mb-1 flex-wrap">
            {tagsWithHash.map((d, index) => (
                <button
                    key={index}
                    onClick={() => onSelect(d)}
                    disabled={d === '#'}
                    className={`py-2 px-[14px] rounded-full
                    ${
                        d === '#'
                            ? 'bg-S_WO_20 text-sm font-medium text-S_Wave_Orange shadow-[inset_0px_-2px_1px_0px_#FFBE9F] border-[0.5px] border-[#FFCFBE] '
                            : 'bg-S_White text-sm font-medium text-S_Dye_1 shadow-[inset_0px_-2px_1px_0px_rgba(0,0,0,0.10)] border-[0.5px] border-S_Dye_White '
                    }
                   `}
                >
                    {d}
                </button>
            ))}
        </div>
    );
};
