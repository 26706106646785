import React, { useEffect } from 'react';
import { BottomBar } from '@components/BottomBar';
import { useNavigate } from 'react-router-dom';
import { ampli } from '../ampli';
import { usePrevTrackingNavigate } from '@shared/hooks/usePrevTracking';

export const Search = () => {
    const items = [
        { id: 1, category: "cut", image: '/research_cut.png', navigateTo: '/search-cut-detail' },
        { id: 2, category: "perm", image: '/research_dye2.png', navigateTo: '/search-dye-detail' },
        { id: 3, category: "color", image: '/research_perm2.png', navigateTo: '/search-perm-detail' },
    ];

    const navigate = useNavigate();
    const { setOrganic } = usePrevTrackingNavigate()

    useEffect(() => {
        setOrganic();
    }, []);

    return (
        <div className="flex flex-col w-full min-h-screen">
            <h1 className="text-2xl font-bold py-[14px] pl-4 bg-white">탐색</h1>
            <div className="flex-grow overflow-auto px-4 pb-16">
                <div className="grid grid-cols-2 gap-3 mt-3">
                    {items.map((item) => (
                        <div key={item.id} className="aspect-w-1 aspect-h-1">
                            <img
                                src={item.image}
                                alt=""
                                className="object-cover rounded-lg w-full h-full cursor-pointer"
                                onClick={() => {
                                    ampli.검색대분류버튼클릭({
                                        searchCategory: item.category
                                    });
                                    navigate(item.navigateTo);
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="fixed bottom-0 left-0 right-0">
                <BottomBar activePage={'/search'} />
            </div>
        </div>
    );
};