import React from 'react';

import { RouterProvider } from 'react-router-dom';
import CustomRouter from './AppRouter';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'tailwindcss/tailwind.css';
import { ampli, ApiKey, DefaultConfiguration } from '../ampli';

const AMPLI_CONFIG = DefaultConfiguration;
const AMPLI_API_KEY = ApiKey.sonigocoredev;

ampli.load({
  client: {
    apiKey: AMPLI_API_KEY,
    configuration: AMPLI_CONFIG,
  },
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
        },
    },
});

queryClient.invalidateQueries();

function App() {
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={CustomRouter} />
            </QueryClientProvider>
        </>
    );
}

export default App;
