import React, { useEffect, useState } from 'react';
import { SonigoLogo } from '@shared/imges/SonigoLogo';
import { XBtn } from '@shared/buttons/XBtn';
import { BackBtn } from '@shared/buttons/BackBtn';
import { useLocation, useNavigate } from 'react-router-dom';
import { BottomBtn } from '@components/BottomBtn';
import { API } from '../api/API';
import { ampli } from '../ampli';

export const NickName = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { phoneNumberToken } = location.state || {};

    const [nickname, setNickname] = useState<string>('');
    const [isValid, setIsValid] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);

    const validateNickName = (value: string) => {
        const regex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{1,12}$/;
        return regex.test(value);
    };

    const handleNickNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newNickname = e.target.value
            .replace(/[^a-zA-Z0-9]/g, '')
            .slice(0, 12);
        setNickname(newNickname);
        setIsValid(validateNickName(newNickname));
    };

    const handleLogin = async () => {
        // 로그인 처리 로직
        if (isValid) {
            const res = await API.auth.SMSSignup(nickname, phoneNumberToken)
            if (!res.data.accessToken) {
                throw new Error('accessToken is not exist');
            }

            ampli.회원가입완료();
            ampli.identify(
                res.data.userId,
                {
                    ["sonigo ID"]: res.data.sonigoId,
                }
            );

            navigate('/');
        }
    };

    useEffect(() => {
        console.log(phoneNumberToken);
    }, [phoneNumberToken]);

    useEffect(() => {
        if (nickname.length > 0) {
            setShowError(true);
        }
    }, [nickname]);

    return (
        <div className="flex flex-col items-center w-full h-dvh">
            <div className="flex justify-between items-center w-full py-[14px] px-[17px]">
                <button onClick={() => navigate(-1)}>
                    <BackBtn />
                </button>
                <div className="flex-grow text-center">
                    <p className="text-black text-lg font-semibold">
                        로그인 / 회원가입
                    </p>
                </div>
                <button onClick={() => navigate('/')}>
                    <XBtn />
                </button>
            </div>
            <div className="flex-grow flex flex-col items-center w-full">
                <div className="mt-[21px] mb-[15px]">
                    <SonigoLogo />
                </div>
                <p className="text-S_Dye_5 text-xl font-medium">
                    닉네임을 입력해 주세요.
                </p>
                <div className="flex flex-col w-full px-4">
                    <p className="flex items-start text-S_Dye_Black text-sm font-semibold mt-8 mb-2 ml-[3px]">
                        닉네임
                    </p>
                    <label htmlFor="nickname">
                        <input
                            type="text"
                            name="nickname"
                            id="nickname"
                            className={`bg-S_Dye_White w-full py-[15px] px-[19px] rounded-[4px] text-S_Dye_Black font-medium
                                ${nickname ? 'border-[1.5px] border-S_Wave_Orange' : 'border-none'}
                                focus:outline-none
                                `}
                            value={nickname}
                            onChange={handleNickNameChange}
                            placeholder="영문, 숫자 포함 12글자 이하"
                        />
                    </label>
                    {!isValid && showError && (
                        <p className="text-S_Wave_Orange text-sm font-medium">
                            영문, 숫자 포함 12글자까지 입력할 수 있어요.
                        </p>
                    )}
                </div>
            </div>
            <div className="w-[90%] mb-6">
                <BottomBtn onClick={handleLogin} disabled={!isValid}>
                    가입하기
                </BottomBtn>
            </div>
        </div>
    );
};
