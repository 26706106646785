import { useQuery, useQueryClient } from '@tanstack/react-query';

export const useAccessToken = () => {
    const queryClient = useQueryClient();

    const { data: accessToken } = useQuery({
        queryKey: ['accessToken'],
        initialData: () => localStorage.getItem('accessToken'),
    });

    const setAccessToken = (newToken: string) => {
        queryClient.setQueryData(['accessToken'], newToken);
        localStorage.setItem('accessToken', newToken);
    };

    return { accessToken, setAccessToken };
};
