import { useState } from 'react';

interface IUseModal<T> {
    modalState: T;
    isModalOpen: boolean;
    openModal: () => void;
    closeModal: () => void;
    setModalState: (state: T) => void;
}

export const useModal = <T>(initialState: T): IUseModal<T> => {
    const [modalState, setModalState] = useState<T>(initialState); // modal 내부 컨텐츠 확장성 고려
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return {
        modalState,
        isModalOpen,
        openModal,
        closeModal,
        setModalState,
    };
};
