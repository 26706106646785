import React, { useEffect, useRef, useState } from 'react';
import { DropdownIcon } from '@shared/icons/DropdownIcon';
import { ProfileIcon } from '@shared/icons/ProfileIcon';
import { BottomSheet } from '../components/BottomSheet';
import { RefreshIcon } from '@shared/icons/RefreshIcon';
import { useModal } from '@shared/hooks/useModal.hook';
import { LegoSadImg } from '@shared/imges/LegoSadImg';
import {
    womenModalContainer,
    womenModalLayout,
    womenModalTransiton,
} from '@shared/style/womenModal.styled';
import {
    loginModalContainer,
    loginModalLayout,
    loginModalTransiton,
} from '@shared/style/loginModal.styled';
import { useOutsideClick } from '@shared/hooks/useOutsideClick.hook';
import { useNavigate } from 'react-router-dom';
import { API, clearAccessTokenCookie } from '../api/API';
import { useUserId } from '@features/useUserId.hook';

interface IData {
    data: string[];
}

const Data: IData = {
    data: [
        '2024 남자머리',
        '샤기컷 붐은 온다',
        '장발이 하고싶나?',
        '헤어도 빈티지',
        '집돌이 무드',
        '짧아서 더 멋짐',
        '미친 개성',
        '깔끔하게 해주세요',
        '뽀글뽀글',
    ],
};

interface MainTopBarProps {
    onChange: (data: string) => void;
    onRefresh: () => void;
}

export const MainTopBar = ({ onChange, onRefresh }: MainTopBarProps) => {
    const navigate = useNavigate();

    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
    const [selectedData, setSelectedData] = useState<string>('2024 남자머리');
    const [selectedGender, setSelectedGender] = useState('남자');
    const [showZIndex, setShowZIndex] = useState(false);
    const { userId } = useUserId();

    const handleOpenBottomSheet = () => {
        setIsBottomSheetOpen(true);
        setShowZIndex(true);
    };
    const handleCloseBottomSheet = () => {
        setIsBottomSheetOpen(false);
        setShowZIndex(false);
    };

    const womenModalRef = useRef<HTMLDivElement>(null);
    const loginModalRef = useRef<HTMLDivElement>(null);
    const {
        isModalOpen: womenModalOpen,
        openModal: womenOpenModal,
        closeModal: womenCloseModal,
    } = useModal<null>(null);

    const {
        isModalOpen: loginModalOpen,
        openModal: loginOpenModal,
        closeModal: loginCloseModal,
    } = useModal<null>(null);

    useOutsideClick(loginModalRef, loginCloseModal, loginModalOpen);

    useEffect(() => {
        if (selectedGender === '여자') {
            womenOpenModal();
            setSelectedGender('남자');
        }
    }, [selectedGender]);

    useEffect(() => {
        console.log('selectedData', selectedData);
        onChange(selectedData);
    }, [selectedData]);

    useEffect(() => {
        setShowZIndex(isBottomSheetOpen || loginModalOpen || womenModalOpen);
    }, [isBottomSheetOpen, loginModalOpen, womenModalOpen]);

    const handleLoginOpenModal = () => {
        if (API.isAuthorized()) {
            loginOpenModal();
        } else {
            navigate('/login');
        }
    };

    const handleLogout = () => {
        const url = window.location.href;
        const parts = url.split('/');
        const newUrl = parts.slice(0, 3).join('/');
        clearAccessTokenCookie();
        loginCloseModal();
        localStorage.setItem('userId', '');
        setShowZIndex(false);
        window.location.href = newUrl;
    };

    const handleDataSelect = (d: string) => {
        setSelectedData(d);
        onChange(d);  // Pass the selected data to the parent component
    };

    return (
        <div
            className={`fixed top-0 left-1/2 transform -translate-x-1/2 max-w-[500px] w-full ${showZIndex ? 'z-50 h-full' : 'z-10'}`}
        >
            <div className="flex flex-col bg-S_White w-full p-4">
                <div className="flex items-center">
                    <button
                        className="flex items-center text-S_Dye_Black font-semibold text-lg gap-3"
                        onClick={handleOpenBottomSheet}
                    >
                        <p>남자</p>
                        <DropdownIcon />
                    </button>
                    <div className="flex-grow"></div>
                    <button onClick={handleLoginOpenModal}>
                        <ProfileIcon />
                    </button>
                </div>
                <div className="flex w-full overflow-hidden mt-[17px]">
                    <button className="flex-shrink-0 mr-2" onClick={onRefresh}>
                        <RefreshIcon />
                    </button>
                    <div className="flex-1 overflow-hidden">
                        <ul className="flex items-center flex-nowrap whitespace-nowrap gap-2 overflow-x-auto scrollbar-hide">
                            {Data.data.map((d, index) => (
                                <li
                                    key={index}
                                    onClick={() => handleDataSelect(d)}
                                    className={`flex items-center justify-center flex-shrink-0 py-2 px-3 font-medium rounded-[4px] cursor-pointer
                            ${selectedData === d
                                            ? 'border-[1.2px] border-S_Wave_Orange text-S_Wave_Orange px-[12px]'
                                            : 'border-[0.5px] border-S_Dye_1 text-S_Dye_1 px-[12.5px]'
                                        }
                            `}
                                >
                                    <span>{d}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            {showZIndex && (
                <div className="fixed inset-0 z-40">
                    <div className={`fixed inset-0`}>
                        <BottomSheet
                            isOpen={isBottomSheetOpen}
                            onClose={handleCloseBottomSheet}
                            setSelectedGender={setSelectedGender}
                        />
                    </div>

                    {womenModalOpen && (
                        <div
                            className={`${womenModalOpen ? 'opacity-100 ' : 'opacity-0'} ${womenModalTransiton.class}`}
                        >
                            <div className={womenModalLayout.class}>
                                <div
                                    ref={womenModalRef}
                                    className={womenModalContainer.class}
                                >
                                    <LegoSadImg />
                                    <p className="mt-8 font-medium text-[#000000]">
                                        여자 머리는 준비 중입니다...
                                    </p>
                                    <p className="mb-[35px] font-medium text-[#000000]">
                                        죄송합니다...
                                    </p>
                                    <button
                                        onClick={() => womenCloseModal()}
                                        className="w-[328px] h-[41px] text-center rounded-[4px] bg-S_Dye_Black"
                                    >
                                        <p className="place-content-center text-lg font-semibold text-S_White">
                                            확인
                                        </p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {loginModalOpen && (
                        <div
                            className={`${loginModalOpen ? 'opacity-100 ' : 'opacity-0'} ${loginModalTransiton.class}`}
                        >
                            <div className={loginModalLayout.class}>
                                <div
                                    ref={loginModalRef}
                                    className={loginModalContainer.class}
                                >
                                    <ProfileIcon width={40} height={40} />
                                    {userId && (
                                        <p className="text-S_Dye_Black font-medium text-sm mt-4">
                                            {userId as string || undefined}
                                        </p>
                                    )}
                                    <div className="flex grow"></div>
                                    <button
                                        className="text-S_Wave_Orange font-semibold text-sm mb-[42px] underline"
                                        onClick={handleLogout}
                                    >
                                        로그아웃
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
