import React from 'react';

interface ProfileIconProps {
    width?: number;
    height?: number;
}

export const ProfileIcon: React.FC<ProfileIconProps> = ({
    width = 28,
    height = 29,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 28 29"
            fill="none"
        >
            <circle cx="14" cy="14.5" r="14" fill="#F2F1F0" />
            <path
                d="M14 14.5C15.933 14.5 17.5 12.933 17.5 11C17.5 9.067 15.933 7.5 14 7.5C12.067 7.5 10.5 9.067 10.5 11C10.5 12.933 12.067 14.5 14 14.5Z"
                stroke="#ACA39A"
                strokeWidth="1.7"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.125 22.1642C6.94799 20.8133 8.10465 19.6968 9.48378 18.9221C10.8629 18.1474 12.4182 17.7405 14 17.7405C15.5818 17.7405 17.1371 18.1474 18.5162 18.9221C19.8954 19.6968 21.052 20.8133 21.875 22.1642"
                stroke="#ACA39A"
                strokeWidth="1.7"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
