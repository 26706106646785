import React from "react";

export const BackBtn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
    >
      <path
        d="M7 1L1.14427 6.67692C1.09873 6.71836 1.06243 6.76841 1.03762 6.82397C1.0128 6.87954 1 6.93945 1 7C1 7.06055 1.0128 7.12046 1.03762 7.17603C1.06243 7.23159 1.09873 7.28164 1.14427 7.32308L7 13"
        stroke="#232222"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
