import { tw } from '@shared/model/Tailwindest';

export const womenModalTransiton = tw.style({
    transitionProperty: 'transition-opacity',
    transitionDuration: 'duration-200',
    transitionTimingFunction: 'ease-in-out',
    position: 'fixed',
    zIndex: 'z-50',
});

export const womenModalLayout = tw.style({
    display: 'flex',
    alignItems: 'items-center',
    justifyContent: 'justify-center',
    position: 'fixed',
    inset: 'inset-0',
    zIndex: 'z-40',
    backgroundColor: 'bg-P3_bg_cloudy',
});

export const womenModalContainer = tw.style({
    display: 'flex',
    flexDirection: 'flex-col',
    alignItems: 'items-center',
    justifyContent: 'justify-center',
    minWidth: 'min-w-[328px]',
    borderWidth: 'border',
    overflow: 'overflow-auto',
    backgroundColor: 'bg-white',
    borderRadius: 'rounded-xl',
    zIndex: 'z-50',
    paddingX: 'px-4',
    paddingTop: 'pt-[61px]',
    paddingBottom: 'pb-3',
});
