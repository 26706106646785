import React from 'react';

interface BottomSheetProps {
    isOpen: boolean;
    onClose: () => void;
    setSelectedGender: React.Dispatch<React.SetStateAction<string>>;
}

export const BottomSheet: React.FC<BottomSheetProps> = ({
    isOpen,
    onClose,
    setSelectedGender,
}) => {
    if (!isOpen) return null;

    const handleMen = () => {
        setSelectedGender('남자');
        onClose();
    };
    const handleWomen = () => {
        setSelectedGender('여자');
        onClose();
    };

    return (
        <>
            <div
                className="fixed inset-0 bg-black bg-opacity-50 z-40"
                onClick={onClose}
            ></div>
            <div className="fixed bottom-0 left-0 right-0 top-[80%] bg-S_White rounded-t-2xl z-50">
                <div className="mt-3">
                    <button
                        className="w-full py-3 border-b border-gray-200"
                        onClick={handleMen}
                    >
                        <p className="text-center font-medium text-S_Dye_Black">
                            남자
                        </p>
                    </button>
                    <button
                        className="w-full py-3"
                        onClick={handleWomen}
                    >
                        <p className="text-center font-medium text-S_Dye_Black">
                            여자
                        </p>
                    </button>
                </div>
            </div>
        </>
    );
};
