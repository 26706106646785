import React, { useState } from 'react';
import { ampli } from '../ampli';

interface IHairstyleBottomSheet {
    isOpen: boolean;
    onClose: () => void;
    setSelectedHairStyle: (style: string) => void;
}

const data = ['전체', '레드', '블루', '옐로우', '브라운', '핑크', '그린', '자연모', '기타'];

export const DyeCategoryBottomSheet = ({
    isOpen,
    onClose,
    setSelectedHairStyle,
}: IHairstyleBottomSheet) => {
    const [selectedData, setSelectedData] = useState<string>('전체');

    if (!isOpen) return null;

    const handleSelectData = (d: string) => {
        setSelectedData(d);
        setSelectedHairStyle(d);
        onClose();
    };

    return (
        <>
            <div
                className="fixed inset-0 bg-black bg-opacity-50 z-40"
                onClick={onClose}
            ></div>
            <div className="fixed bottom-0 left-0 right-0 bg-S_White rounded-t-2xl z-50 p-8 max-h-[80%] overflow-y-auto">
                <h2 className="text-sm font-medium text-center mt-2 mb-3">염색</h2>
                <div className="flex flex-wrap gap-2 justify-center">
                    {data.map((d, index) => (
                        <button
                            key={index}
                            onClick={() => {
                                if (d != '전체') {
                                    ampli.검색용상단태그더보기에서태그선택({
                                        searchCategory: "color",
                                        tag: d
                                    });
                                }
                                handleSelectData(d)
                            }}
                            className={`py-2 px-4 rounded-full text-sm font-medium
                            ${selectedData === d
                                    ? 'bg-S_WO_20 text-sm font-medium text-S_Wave_Orange shadow-[inset_0px_2px_1px_0px_#FFBE9F] border-[0.5px] border-[#FFCFBE]'
                                    : 'bg-S_White text-sm font-medium text-S_Dye_1 shadow-[inset_0px_-2px_1px_0px_rgba(0,0,0,0.10)] border-[0.5px] border-S_Dye_White'
                                }`}
                        >
                            {d}
                        </button>
                    ))}
                </div>
            </div>
        </>
    );
};